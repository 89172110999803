const clientId = "FmL7KXYF0NtbOWnFFZACrqSUJ66yEm3IiZPgSSut";
const redirect_uri = "https%3A%2F%2Fwww.celgreports.net%2F";
const clioURL = "https://app.clio.com/oauth/authorize";
const clioState = "retrieved";

const apiurl = "https://lvg24haprg.execute-api.us-east-1.amazonaws.com/prod";

export async function getToken(auth, code) {
  const token = await auth.getToken();
  const response = await fetch(`${apiurl}/clio/token?code=${code}`, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export async function refreshToken(auth) {
  const token = await auth.getToken();
  const response = await fetch(`${apiurl}/clio/refresh-token`, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export const askClio = () => {
  window.location = `${clioURL}?response_type=code&client_id=${clientId}&redirect_uri=${redirect_uri}&state=${clioState}`;
};

export const getClioData = async (
  auth,
  clio_token,
  fields,
  issued_after,
  issued_before,
  endpoint
) => {
  const status = "open,pending";
  const state = "draft, awaiting_approval, awaiting_payment, paid";
  const url = `${apiurl}/clio/data/${endpoint}?token=${clio_token}&fields=${fields}&issued_after=${issued_after}&issued_before=${issued_before}&state=${state}&status=${status}&order=id(asc)`;

  const encoded = encodeURI(url);

  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export const getPersistedData = async (auth, endpoint) => {
  const url = `${apiurl}/dynamodb/${endpoint}`;

  const encoded = encodeURI(url);
  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export async function addExpense(auth, payload) {
  const token = await auth.getToken();
  const response = await fetch(`${apiurl}/dynamodb/expenses`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: payload,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export const deleteExpense = async (auth, attorney, date) => {
  const token = await auth.getToken();
  const response = await fetch(
    `${apiurl}/dynamodb/expenses?attorney=${attorney}&date=${date}`,
    {
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    }
  );
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export const getGroups = async (auth) => {
  const url = `${apiurl}/dynamodb/groups`;
  const encoded = encodeURI(url);
  const token = await auth.getToken();
  const response = await fetch(encoded, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};

export async function editGroup(auth, payload) {
  const token = await auth.getToken();
  const response = await fetch(`${apiurl}/dynamodb/groups`, {
    method: "POST",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      payload: payload,
    }),
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
}

export const deleteGroup = async (auth, id) => {
  const token = await auth.getToken();
  const response = await fetch(`${apiurl}/dynamodb/groups?id=${id}`, {
    method: "DELETE",
    headers: {
      Authorization: token,
    },
  });
  return response.json().then((response) => {
    if (response.body?.errorMessage) {
      throw Error(response.body.errorMessage);
    }
    return response;
  });
};
