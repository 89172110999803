import { applyRange } from "./utils";
import { expenseformulas } from "./formulas";

const formatter = new Intl.DateTimeFormat("en", {
  month: "long",
  year: "numeric",
});

export const expenses = (expensesSheet, attorney, persistedData, grefs) => {
  const a1 = expensesSheet.getCell("A1");
  const b1 = expensesSheet.getCell("B1");
  a1.value = "Attorney:";
  b1.value = attorney;

  //HEADERS
  const b6 = expensesSheet.getCell("B6");
  b6.value = "Revenue % Formula";
  const c6 = expensesSheet.getCell("C6");
  c6.value = "Attorney Origination";
  const d6 = expensesSheet.getCell("D6");
  d6.value = "Firm Revenue";
  const e6 = expensesSheet.getCell("E6");
  e6.value = "Rate";
  const f6 = expensesSheet.getCell("F6");
  f6.value = "Attorney Direct Expenses";
  const g6 = expensesSheet.getCell("G6");
  g6.value = "Total General Expenses";
  const h6 = expensesSheet.getCell("H6");
  h6.value = "Total Attorney Expense Allocation";
  const i6 = expensesSheet.getCell("I6");
  i6.value = "Distribtion Adjustment";

  expensesSheet.columns = [
    { key: "blank", width: 10 },
    { key: "revform", width: 21.8 },
    { key: "Attorney Origination", width: 19.5 },
    { key: "Firm Revenue", width: 18.8 },
    { key: "rate", width: 8.1 },
    { key: "Attorney Direct Expenses", width: 14 },
    { key: "Total General Expenses", width: 14 },
    { key: "Total Attorney Expense Allocation", width: 14 },
    { key: "Distribtion Adjustment", width: 14 },
  ];

  const calculatedCELGValues = grefs.calculatedCELGValues.current[attorney];

  if (!calculatedCELGValues) {
    calculateExpenses(persistedData, attorney, grefs);
  }
  const calculatedValues = grefs.calculatedExpenseValues.current;

  let startRow = 7;
  let row = startRow;
  if (attorney in persistedData.expenses) {
    Object.values(persistedData.expenses[attorney])
      .sort((a, b) => {
        const ad = new Date(a.date);
        const ab = new Date(b.date);
        return ad > ab ? 1 : ab > ad ? -1 : 0;
      })
      .forEach((expense) => {
        expense.date = formatter.format(new Date(expense.date));
        const b = expensesSheet.getCell(`B${row}`);
        const c = expensesSheet.getCell(`C${row}`);
        const d = expensesSheet.getCell(`D${row}`);
        const e = expensesSheet.getCell(`E${row}`);
        const f = expensesSheet.getCell(`F${row}`);
        const g = expensesSheet.getCell(`G${row}`);
        const h = expensesSheet.getCell(`H${row}`);
        const i = expensesSheet.getCell(`I${row}`);
        //VALUES
        b.value = expense.date;
        c.value = +expense.AttorneyOrigination.replace(",", ".");
        d.value = +expense.FirmRevenue.replace(",", ".");
        f.value = +expense.AttorneyDirectExpenses.replace(",", ".");
        g.value = +expense.TotalGeneralExpenses.replace(",", ".");

        //FORMAT
        applyRange(
          expensesSheet,
          `C${row}:D${row}`,
          "numFmt",
          '_("$"* # ##0,00_);_("$"* (# ##0,00);_("$"* "-"??_);_(@_)'
        );
        e.numFmt = "0%";
        applyRange(
          expensesSheet,
          `F${row}:I${row}`,
          "numFmt",
          '_("$"* # ##0,00_);_("$"* (# ##0,00);_("$"* "-"??_);_(@_)'
        );
        //BORDER
        b.border = {
          left: { style: "double", color: { argb: "FF000000" } },
        };
        i.border = {
          right: { style: "double", color: { argb: "FF000000" } },
        };
        //ALIGNMENT
        b.alignment = {
          vertical: "middle",
          horizontal: "left",
        };
        //FONT
        b.font = {
          name: "Calibri",
          size: 12,
          bold: "true",
        };
        //FILL
        applyRange(expensesSheet, `H${row}:I${row}`, "fill", {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F8CBAD" },
        });
        e.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F8CBAD" },
        };
        //FORMULAS
        e.value = {
          formula: `IF(OR(ISBLANK(D${row}),D${row}=0),"",C${row}/D${row})`,
          result: calculatedValues["E"][row],
        };
        h.value = {
          formula: `(G${row}+F${row})*E${row}`,
          result: calculatedValues["H"][row],
        };
        i.value = {
          formula: `-H${row}`,
          result: calculatedValues["I"][row],
        };

        row++;
      });

    row = row - 1;
  }

  //ALIGNENT
  applyRange(expensesSheet, "B6:I6", "alignment", {
    vertical: "bottom",
    horizontal: "center",
    wrapText: true,
  });
  //FILL
  applyRange(expensesSheet, "B6:I6", "fill", {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D9D9D9" },
  });
  //FONT
  applyRange(expensesSheet, "A1:B1", "font", {
    name: "Calibri",
    size: 12,
    bold: "true",
  });
  applyRange(expensesSheet, "B6:I6", "font", {
    name: "Calibri",
    size: 12,
    bold: "true",
  });
  //BORDER

  applyRange(expensesSheet, `C${row}:H${row}`, "border", {
    bottom: { style: "double", color: { argb: "FF000000" } },
  });
  expensesSheet.getCell(`B${row}`).border = {
    left: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  };
  expensesSheet.getCell(`I${row}`).border = {
    right: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  };

  applyRange(expensesSheet, "C6:H6", "border", {
    top: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "thick", color: { argb: "FF000000" } },
  });
  expensesSheet.getCell(`B6`).border = {
    top: { style: "double", color: { argb: "FF000000" } },
    left: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "thick", color: { argb: "FF000000" } },
  };
  expensesSheet.getCell(`I6`).border = {
    top: { style: "double", color: { argb: "FF000000" } },
    right: { style: "double", color: { argb: "FF000000" } },
    bottom: { style: "thick", color: { argb: "FF000000" } },
  };
};

export const calculateExpenses = (persistedData, attorney, grefs) => {
  let startRow = 7;
  let row = startRow;
  if (attorney in persistedData.expenses) {
    Object.values(persistedData.expenses[attorney]).forEach((expense) => {
      expense.date = formatter.format(new Date(expense.date));
      expenseformulas(row, "E", expense, grefs, attorney);
      expenseformulas(row, "H", expense, grefs, attorney);
      expenseformulas(row, "I", expense, grefs, attorney);
      row++;
    });
  }
};
