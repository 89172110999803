import { useContext } from "react";
import { StoreContext } from "../../store/Store";
import { Result } from "../utils";

const endpoints = ["allData", "bills", "matters", "persisted", "activities"];
const display = {
  bills: "Get Billing Data",
  matters: "Get Matters Data",
  persisted: "Get Persisted Data",
  allData: "Get All the Data",
  activities: "Get Activities Data",
};

const buttonStyle = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  backgroundColor: "#B0D9CD",
  color: "#000000",
  padding: 8,
  border: "3px solid black",
  borderRadius: 8,
  cursor: "pointer",
  gap: 10,
};
const buttonStyleMinor = {
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  backgroundColor: "#F9C48C",
  color: "#000000",
  padding: 8,
  border: "1px solid black",
  borderRadius: 8,
  cursor: "pointer",
  gap: 10,
  fontWeight: "normal",
  fontSize: 12,
};

export const ButtonsToRetrieveData = ({ onClick, errorMessage }) => {
  const [store] = useContext(StoreContext);
  const { status } = store;

  return (
    <div style={{ display: "flex", gap: 10 }}>
      {endpoints.map((item, i) => {
        return (
          <div
            key={i}
            style={i === 0 ? buttonStyle : buttonStyleMinor}
            onClick={() => onClick(item)}
          >
            {display[item]}
            <Result status={status[item]} />
          </div>
        );
      })}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {errorMessage}
      </div>
    </div>
  );
};
