import { fieldsparameters } from "./data/fields";

export const persisted = async (
  getPersistedData,
  auth,
  dispatch,
  actionTypes
) => {
  const pdata = {};
  const pKeyeddata = {};
  const paymentsKeyed = {};
  const expensesKeyed = {};
  const result = await getPersistedData(auth, "matters");
  const mattersRawData = JSON.parse(result.body);
  const resultp = await getPersistedData(auth, "payments");
  const paymentsRawData = JSON.parse(resultp.body);
  const resulte = await getPersistedData(auth, "expenses");
  const expensesRawData = JSON.parse(resulte.body);

  const mattersRawDataClean = mattersRawData.map((item) => {
    return Object.entries(item).reduce((a, b) => {
      return { ...a, [b[0]]: b[1] === "None" ? "" : b[1] };
    }, {});
  });

  const paymentsRawDataClean = paymentsRawData.map((item) => {
    return Object.entries(item).reduce((a, b) => {
      return { ...a, [b[0]]: b[1] === "None" ? "" : b[1] };
    }, {});
  });

  const expensesRawDataClean = expensesRawData.map((item) => {
    return Object.entries(item).reduce((a, b) => {
      return { ...a, [b[0]]: b[1] === "None" ? "" : b[1] };
    }, {});
  });

  const mattersKeyed = mattersRawDataClean.reduce((a, b) => {
    return { ...a, [b.id]: b };
  }, {});

  paymentsRawDataClean.forEach((item) => {
    if (item.id in paymentsKeyed) {
      paymentsKeyed[item.id][item.invoice] = item;
    } else {
      paymentsKeyed[item.id] = { [item.invoice]: item };
    }
  });

  expensesRawDataClean.forEach((item) => {
    if (item.attorney in expensesKeyed) {
      expensesKeyed[item.attorney][item.date] = item;
    } else {
      expensesKeyed[item.attorney] = { [item.date]: item };
    }
  });

  pKeyeddata["matters"] = mattersKeyed;
  pKeyeddata["payments"] = paymentsKeyed;
  pKeyeddata["expenses"] = expensesKeyed;
  pdata["matters"] = mattersRawDataClean;
  pdata["payments"] = paymentsRawDataClean;
  pdata["expenses"] = expensesRawDataClean;

  dispatch({
    type: actionTypes.SET_PERSISTED_KEYED_DATA,
    payload: pKeyeddata,
  });
  dispatch({
    type: actionTypes.SET_PERSISTED_DATA,
    payload: pdata,
  });
};

export const clio = async (
  getClioData,
  auth,
  access_token,
  issued_after,
  issued_before,
  dispatch,
  actionTypes,
  status,
  endpoint,
  setErrorMessage
) => {
  const fields = fieldsparameters[endpoint].replace(/(\r\n|\n|\r)/gm, "");

  const result = await getClioData(
    auth,
    access_token,
    fields,
    issued_after,
    issued_before,
    endpoint
  );
  const clio_result = JSON.parse(result.body);

  if (result.statusCode === 200) {
    const cleanedData = clio_result.data.filter(
      (item) => item.state !== "void" && item.state !== "deleted"
    );

    dispatch({
      type:
        endpoint === "bills"
          ? actionTypes.SET_CLIO_BILLS
          : endpoint === "activities"
          ? actionTypes.SET_CLIO_ACTIVITIES
          : actionTypes.SET_CLIO_MATTERS,
      payload: cleanedData,
    });
    if (endpoint === "activities") {
      const activityKeyedData = {};
      const activityAttorneyMatter = {};
      cleanedData
        .filter((f) => f.bill && f.user)
        .forEach((item) => {
          if (item.bill.number in activityKeyedData) {
            if (item.user.name in activityKeyedData[item.bill.number]) {
              activityKeyedData[item.bill.number][item.user.name] += item.total;
            } else {
              activityKeyedData[item.bill.number][item.user.name] = item.total;
            }
          } else {
            activityKeyedData[item.bill.number] = {
              [item.user.name]: item.total,
            };
          }
          if (item.total) {
            if (item.user.name in activityAttorneyMatter) {
              activityAttorneyMatter[item.user.name].add(
                item.matter.display_number
              );
            } else {
              activityAttorneyMatter[item.user.name] = new Set([
                item.matter.display_number,
              ]);
            }
          }
        });

      dispatch({
        type: actionTypes.SET_CLIO_ACTIVITIES_KEYED,
        payload: activityKeyedData,
      });
      dispatch({
        type: actionTypes.SET_CLIO_ACTIVITIES_ATTORNEY_MATTER,
        payload: activityAttorneyMatter,
      });
    }
  } else {
    const errorMessage = clio_result.error;
    setErrorMessage(errorMessage);
    dispatch({
      type: actionTypes.SET_STATUS,
      payload: { ...status, [endpoint]: "fail" },
    });
  }
};
