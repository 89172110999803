import React, { useContext } from "react";
import { StoreContext } from "../store/Store";

export const NewAuth = ({ mode }) => {
  const [store] = useContext(StoreContext);
  const { code, access_token,refresh_token } = store;
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing:"border-box"
      }}
    >
      <div>
        <h1>New Authorization</h1>
        <div>{`Code : ${code}`}</div>
        <div>{`Access Token : ${access_token}`}</div>
        <div>{`Refresh Token : ${refresh_token}`}</div>
      </div>
    </div>
  );
};
