import { actionTypes } from "./storeTypes";

export const init_global = {
  auth: undefined,
  access_token: null,
  clioActivities: null,
  clioActivitiesKeyed: null,
  clioActivitiesAttorneyMatters: null,
  clioBills: null,
  clioMatters: null,
  credentials: null,
  expenseRow: null,
  expensesFiltered: null,
  editedGroup: undefined,
  forgotPassword: { status: false, user: "" },
  issuedAfter: new Date("2023-11-31"),
  issuedBefore: new Date(),
  loggedIn: { status: false, message: "" },
  groupName: "",
  groups: null,
  mode: "add",
  persistedData: {},
  persistedKeyedData: {},
  refresh_token: null,
  runOption: "Run for all groups and unassigned attorneys",
  runAttorneys: [],
  runGroups: [],
  code: null,
  requireNewPassword: false,
  selected: "Data",
  selectedGroup: null,
  selectedGroupAttorney: null,
  status: {},
  showDataForEndpoint: "bills",
  tableWidths: null,
};

export function global_reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_AUTH: {
      return {
        ...state,
        auth: action.payload,
      };
    }
    case actionTypes.SET_CLIO_ACTIVITIES: {
      return {
        ...state,
        clioActivities: action.payload,
      };
    }
    case actionTypes.SET_CLIO_ACTIVITIES_KEYED: {
      return {
        ...state,
        clioActivitiesKeyed: action.payload,
      };
    }
    case actionTypes.SET_CLIO_ACTIVITIES_ATTORNEY_MATTER: {
      return {
        ...state,
        clioActivitiesAttorneyMatters: action.payload,
      };
    }
    case actionTypes.SET_CLIO_BILLS: {
      return {
        ...state,
        clioBills: action.payload,
      };
    }
    case actionTypes.SET_CLIO_MATTERS: {
      return {
        ...state,
        clioMatters: action.payload,
      };
    }
    case actionTypes.SET_CREDENTIALS: {
      return {
        ...state,
        credentials: action.payload,
      };
    }
    case actionTypes.SET_EXPENSE_ROW: {
      return {
        ...state,
        expenseRow: action.payload,
      };
    }
    case actionTypes.SET_EDITED_GROUP: {
      return {
        ...state,
        editedGroup: action.payload,
      };
    }
    case actionTypes.SET_FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPassword: action.payload,
      };
    }
    case actionTypes.SET_ISSUED_AFTER: {
      return {
        ...state,
        issuedAfter: action.payload,
      };
    }
    case actionTypes.SET_ISSUED_BEFORE: {
      return {
        ...state,
        issuedBefore: action.payload,
      };
    }
    case actionTypes.SET_EXPENSES_FILTERED: {
      return {
        ...state,
        expensesFiltered: action.payload,
      };
    }
    case actionTypes.SET_LOGGED_IN: {
      return {
        ...state,
        loggedIn: action.payload,
      };
    }
    case actionTypes.SET_MODE: {
      return {
        ...state,
        mode: action.payload,
      };
    }
    case actionTypes.SET_GROUP_NAME: {
      return {
        ...state,
        groupName: action.payload,
      };
    }
    case actionTypes.SET_GROUPS: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case actionTypes.SET_PERSISTED_DATA: {
      return {
        ...state,
        persistedData: action.payload,
      };
    }
    case actionTypes.SET_STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }
    case actionTypes.SET_PERSISTED_KEYED_DATA: {
      return {
        ...state,
        persistedKeyedData: action.payload,
      };
    }
    case actionTypes.SET_REQUIRE_NEW_PASSWORD: {
      return {
        ...state,
        requireNewPassword: action.payload,
      };
    }
    case actionTypes.SET_CODE: {
      return {
        ...state,
        code: action.payload,
      };
    }
    case actionTypes.SET_RUN_OPTION: {
      return {
        ...state,
        runOption: action.payload,
      };
    }
    case actionTypes.SET_RUN_ATTORNEYS: {
      return {
        ...state,
        runAttorneys: action.payload,
      };
    }
    case actionTypes.SET_RUN_GROUPS: {
      return {
        ...state,
        runGroups: action.payload,
      };
    }
    case actionTypes.SET_SELECTED: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_GROUP: {
      return {
        ...state,
        selectedGroup: action.payload,
      };
    }
    case actionTypes.SET_SELECTED_GROUP_ATTORNEY: {
      return {
        ...state,
        selectedGroupAttorney: action.payload,
      };
    }
    case actionTypes.SET_ACCESS_TOKEN: {
      return {
        ...state,
        access_token: action.payload,
      };
    }
    case actionTypes.SET_SHOW_DATA_FOR_ENDPOINT: {
      return {
        ...state,
        showDataForEndpoint: action.payload,
      };
    }
    case actionTypes.SET_REFRESH_TOKEN: {
      return {
        ...state,
        refresh_token: action.payload,
      };
    }
    case actionTypes.SET_TABLE_WIDTHS: {
      return {
        ...state,
        tableWidths: action.payload,
      };
    }
    default:
      return state;
  }
}
