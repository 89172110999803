import { useContext } from "react";
import { StoreContext } from "../store/Store";
import { actionTypes } from "../store/storeTypes";

const options = [
  "Run for all groups and unassigned attorneys",
  "Run for all attorneys",
  "Select attorneys",
  "Select groups",
];

export const RunOptions = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { runOption } = store;

  const RadioControl = ({ item }) => {
    return (
      <div
        style={{
          marginTop: 5,
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: 20,
        }}
      >
        <input
          checked={item === runOption}
          name="options"
          type="radio"
          onChange={(e) => {
            dispatch({ type: actionTypes.SET_RUN_OPTION, payload: item });
          }}
        ></input>
        <div>{item}</div>
      </div>
    );
  };

  return (
    <div style={{}}>
      {options.map((item, i) => {
        return <RadioControl key={i} item={item} />;
      })}
    </div>
  );
};
