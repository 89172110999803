import { applyRange } from "./utils";
import { formulas } from "./formulas";
import { calculateExpenses } from "./expenses";

const formatter = new Intl.DateTimeFormat("en", {
  month: "long",
  year: "numeric",
});

const NUMBER_FORMAT =
  '_("$"* # ##0.00_);_("$"* (# ##0.00);_("$"* "-"??_);_(@_)';

export const payments = (
  paymentSheet,
  billingData,
  attorney,
  mattersData,
  persistedData,
  clioActivitiesKeyed,
  clioActivitiesAttorneyMatters,
  grefs
) => {
  const actionInvoices = Object.entries(clioActivitiesKeyed)
    .filter((fitem) => {
      return Object.keys(fitem[1]).includes(attorney);
    })
    .map((item) => {
      return item[0];
    });

  const filteredMatters = mattersData.filter((item) => {
    return (
      item.responsible_attorney.name === attorney ||
      item.originating_attorney.name === attorney
    );
  });

  const filteredMattersOnlyName = filteredMatters.map(
    (item) => item.display_number
  );

  const billingMatters = billingData.map(
    (item) => item.matters[0].display_number
  );

  const invoicedMatters = Array.from(clioActivitiesAttorneyMatters[attorney]);

  const displayedMatters = mattersData.filter((item) => {
    return (
      (item.responsible_attorney.name === attorney ||
        item.originating_attorney.name === attorney ||
        invoicedMatters.includes(item.display_number)) &&
      billingMatters.includes(item.display_number)
    );
  });




  //max rows for attorney
  const mattersStartRow = 8;
  const MATTERS = displayedMatters.length + mattersStartRow - 1;
  const expensesStartRow = 7;
  const EXPENSES =
    attorney in persistedData.expenses
      ? Object.keys(persistedData.expenses[attorney]).length +
        expensesStartRow -
        1
      : 1;

  const calculatedCELGValues = grefs.calculatedCELGValues.current[attorney];

  if (!calculatedCELGValues) {
    calculateExpenses(persistedData, attorney, grefs);
  }

  const keyedBills = {};
  //organise data
  billingData
    .filter((f) => {
      return (
        filteredMattersOnlyName.includes(f.matters[0].display_number) ||
        actionInvoices.includes(f.number)
      );
    })
    .forEach((bill) => {
      const keyDate = new Date(bill.issued_at);
      const monthyear = formatter.format(keyDate);

      if (monthyear in keyedBills) {
        keyedBills[monthyear].push(bill);
      } else {
        keyedBills[monthyear] = [bill];
      }
    });

  paymentSheet.views = [{ state: "frozen", xSplit: 3, ySplit: 7 }];

  const a1 = paymentSheet.getCell("A1");
  const b1 = paymentSheet.getCell("B1");
  a1.value = "Attorney:";
  b1.value = attorney;

  //Merged Column headers
  paymentSheet.mergeCells("A5", "A7");
  const a5 = paymentSheet.getCell("A5");
  a5.value = "Month";
  paymentSheet.mergeCells("B5", "B7");
  const b5 = paymentSheet.getCell("B5");
  b5.value = "MATTER";
  paymentSheet.mergeCells("C5", "C7");
  const c5 = paymentSheet.getCell("C5");
  c5.value = "";
  paymentSheet.mergeCells("D5", "D7");
  const d5 = paymentSheet.getCell("D5");
  d5.value = "Invoice Number";
  paymentSheet.mergeCells("E5", "E7");
  const e5 = paymentSheet.getCell("E5");
  e5.value = "Invoiced Amount Total";
  paymentSheet.mergeCells("F5", "F7");
  const f5 = paymentSheet.getCell("F5");
  f5.value = "Collected (Y/N)";
  paymentSheet.mergeCells("G5", "G7");
  const g5 = paymentSheet.getCell("G5");
  g5.value = "";
  paymentSheet.mergeCells("H5", "H7");
  const h5 = paymentSheet.getCell("H5");
  h5.value = "Invoiced Amount Atty";
  paymentSheet.mergeCells("I5", "I7");
  const i5 = paymentSheet.getCell("I5");
  i5.value = "Originating Atty";
  paymentSheet.mergeCells("J5", "J7");
  const j5 = paymentSheet.getCell("J5");
  j5.value = "Responsible Atty";
  paymentSheet.mergeCells("K5", "K7");
  const k5 = paymentSheet.getCell("K5");
  k5.value = "";
  paymentSheet.mergeCells("L5", "L7");
  const l5 = paymentSheet.getCell("L5");
  l5.value = "Guaranteed";
  paymentSheet.mergeCells("M5", "M7");
  const m5 = paymentSheet.getCell("M5");
  m5.value = "Previously Paid";
  paymentSheet.mergeCells("N5", "N7");
  const n5 = paymentSheet.getCell("N5");
  n5.value = "";
  paymentSheet.mergeCells("O5", "O7");
  const o5 = paymentSheet.getCell("O5");
  o5.value = "On-Collection 55%";
  paymentSheet.mergeCells("P5", "P7");
  const p5 = paymentSheet.getCell("P5");
  p5.value = "Responsible 10%";
  paymentSheet.mergeCells("Q5", "Q7");
  const q5 = paymentSheet.getCell("Q5");
  q5.value = "Originating    25%";
  paymentSheet.mergeCells("R5", "R7");
  const r5 = paymentSheet.getCell("R5");
  r5.value = "Attorney Designated Expenses";
  paymentSheet.mergeCells("S5", "S7");
  const s5 = paymentSheet.getCell("S5");
  s5.value = "Custom Adjustments";
  paymentSheet.mergeCells("T5", "T7");
  const t5 = paymentSheet.getCell("T5");
  t5.value = "Previously Paid";
  paymentSheet.mergeCells("U5", "U7");
  const u5 = paymentSheet.getCell("U5");
  u5.value = "";
  paymentSheet.mergeCells("V5", "V7");
  const v5 = paymentSheet.getCell("V5");
  v5.value = "Total Origination";
  paymentSheet.mergeCells("W5", "W7");
  const w5 = paymentSheet.getCell("W5");
  w5.value = "DUE NOW";
  paymentSheet.mergeCells("X5", "X7");
  const x5 = paymentSheet.getCell("X5");
  x5.value = "DUE LATER";
  paymentSheet.mergeCells("Y5", "Y7");
  const y5 = paymentSheet.getCell("Y5");
  y5.value = "TOTAL DUE";

  paymentSheet.mergeCells("Z5", "AA6");
  const z5 = paymentSheet.getCell("Z5");
  z5.value = "Payment Date";

  const z7 = paymentSheet.getCell("Z7");
  z7.value = "Guaranteed";
  const aa7 = paymentSheet.getCell("AA7");
  aa7.value = "On-Collection";

  paymentSheet.columns = [
    { key: "month", width: 18 },
    { key: "matter", width: 45 },
    { key: "blank1", width: 1 },
    { key: "invoicenumber", width: 25 },
    { key: "invoicedamounttotal", width: 14 },
    { key: "collected", width: 14 },
    { key: "blank2", width: 1 },
    { key: "invoiceamount", width: 14 },
    { key: "originatingatt", width: 14 },
    { key: "responsibleatt", width: 14 },
    { key: "blank3", width: 1 },
    { key: "guaranteed", width: 14 },
    { key: "previouslypaid", width: 14 },
    { key: "blank3", width: 1 },
    { key: "on-collection", width: 14 },
    { key: "responsible", width: 14 },
    { key: "originating", width: 14 },
    { key: "celg", width: 14 },
    { key: "custom-adjustments", width: 14 },
    { key: "previouslypaid2", width: 14 },
    { key: "blank4", width: 1 },
    { key: "total-origination", width: 14 },
    { key: "due-now", width: 14 },
    { key: "due-later", width: 14 },
    { key: "total-due", width: 14 },
    { key: "pd-guaranteed", width: 14 },
    { key: "pd-on-collection", width: 14 },
  ];
  const calculatedValues = grefs.calculatedPaymentValues.current;

  let startRow = 8;
  let row = startRow;
  const sumFormulas = {};
  const totals = {
    total: 0,
    paid: 0,
    guaranteed: 0,
    onCollection: 0,
    responsible: 0,
    originating: 0,
    celg: 0,
    totalOrigination: 0,
    dueNow: 0,
    dueLater: 0,
    totalDue: 0,
  };
  const grandTotals = {
    total: 0,
    paid: 0,
    guaranteed: 0,
    onCollection: 0,
    responsible: 0,
    originating: 0,
    celg: 0,
    totalOrigination: 0,
    dueNow: 0,
    dueLater: 0,
    totalDue: 0,
  };
  Object.keys(keyedBills)
    .sort((a, b) => {
      return new Date(a) > new Date(b) ? 1 : new Date(a) < new Date(b) ? -1 : 0;
    })
    .forEach((item, i) => {
      //MONTH HEADER
      paymentSheet.getRow(row).values = [item];
      paymentSheet.getCell(`A${row}`).font = {
        name: "Calibri",
        size: 12,
        bold: "true",
      };
      applyRange(paymentSheet, `A${row}:AA${row}`, "fill", {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D9D9D9" },
      });
      row++;
      sumFormulas[item] = { start: row };
      keyedBills[item].forEach((bill, j) => {
        const persisted =
          bill.matters[0].display_number in persistedData.payments &&
          bill.number in persistedData.payments[bill.matters[0].display_number]
            ? persistedData.payments[bill.matters[0].display_number][
                bill.number
              ]
            : {
                InvoicedAmountAtty: 0,
                CustomAdjustment: 0,
                CustomAdjustmentPrevPaid: "N",
                GuaranteedPrevPaid: "N",
              };

        const persistedMatters =
          bill.matters[0].display_number in persistedData.matters
            ? persistedData.matters[bill.matters[0].display_number]
            : {
                Guaranteed: "",
                "On-Collection": "",
                Originating: "",
                Responsible: "",
              };

        const issuedDate = formatter.format(new Date(bill.issued_at));

        const revenueAmount =
          clioActivitiesKeyed[bill.number] &&
          clioActivitiesKeyed[bill.number][attorney]
            ? clioActivitiesKeyed[bill.number][attorney]
            : 0;

        //VALUES
        paymentSheet.getCell(`A${row}`).value = issuedDate;
        paymentSheet.getCell(`B${row}`).value = bill.matters[0].display_number;
        // paymentSheet.getCell(`C${row}`).value = "";
        paymentSheet.getCell(`D${row}`).value = bill.number;
        paymentSheet.getCell(`E${row}`).value = bill.total;
        paymentSheet.getCell(`F${row}`).value =
          bill.state === "paid" ? "Y" : "N";
        // paymentSheet.getCell(`G${row}`).value = "";
        paymentSheet.getCell(`H${row}`).value = revenueAmount;
        /*********GET FROM STORE***************/
        paymentSheet.getCell(`M${row}`).value = persisted.GuaranteedPrevPaid;
        paymentSheet.getCell(`S${row}`).value =
          persisted.CustomAdjustment === "" ? "" : +persisted.CustomAdjustment;
        paymentSheet.getCell(`T${row}`).value =
          persisted.CustomAdjustmentPrevPaid;
        paymentSheet.getCell(`Z${row}`).value = persisted.GuaranteedPaymentDate
          ? new Date(persisted.GuaranteedPaymentDate)
          : "";
        paymentSheet.getCell(`AA${row}`).value =
          persisted.OnCollectionPaymentDate
            ? new Date(persisted.OnCollectionPaymentDate)
            : "";

        //FILL
        applyRange(paymentSheet, `I${row}:Y${row}`, "fill", {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "F8CBAD" },
        });

        paymentSheet.getCell(`B${row}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: filteredMattersOnlyName.includes(
              bill.matters[0].display_number
            )
              ? "C6E0B4"
              : "FFFFFF",
          },
        };
        paymentSheet.getCell(`C${row}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "BFBFBF" },
        };
        paymentSheet.getCell(`F${row}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
        };
        paymentSheet.getCell(`G${row}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "BFBFBF" },
        };
        paymentSheet.getCell(`K${row}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "BFBFBF" },
        };
        paymentSheet.getCell(`M${row}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
        };
        paymentSheet.getCell(`N${row}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "BFBFBF" },
        };
        paymentSheet.getCell(`S${row}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF" },
        };
        paymentSheet.getCell(`T${row}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFF00" },
        };
        paymentSheet.getCell(`U${row}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "BFBFBF" },
        };
        //ALIGNMENT
        paymentSheet.getCell(`D${row}`).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        paymentSheet.getCell(`F${row}`).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        paymentSheet.getCell(`I${row}`).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        paymentSheet.getCell(`J${row}`).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        paymentSheet.getCell(`M${row}`).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        paymentSheet.getCell(`T${row}`).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        //FONT
        paymentSheet.getCell(`D${row}`).font = {
          name: "Calibri",
          size: 12,
          bold: "true",
        };
        //format
        paymentSheet.getCell(`E${row}`).numFmt = NUMBER_FORMAT;
        paymentSheet.getCell(`H${row}`).numFmt = NUMBER_FORMAT;
        paymentSheet.getCell(`L${row}`).numFmt = NUMBER_FORMAT;
        applyRange(paymentSheet, `O${row}:S${row}`, "numFmt", NUMBER_FORMAT);
        applyRange(paymentSheet, `V${row}:Y${row}`, "numFmt", NUMBER_FORMAT);
        applyRange(paymentSheet, `Z${row}:AA${row}`, "numFmt", "yyyy/mm/dd");
        //FORMULAS
        paymentSheet.getCell(`I${row}`).value = {
          formula: `IF((ISNUMBER(MATCH($B${row},Matters!$B$8:$B$${MATTERS},0))=TRUE),INDEX(Matters!$C$8:$C$${MATTERS},MATCH($B${row},Matters!$B$8:$B$${MATTERS},0)),"N")`,
          result: formulas(
            displayedMatters,
            paymentSheet,
            row,
            "I",
            attorney,
            persisted,
            persistedMatters,
            persistedData,
            grefs
          ),
        };
        paymentSheet.getCell(`J${row}`).value = {
          formula: `IF((ISNUMBER(MATCH($B${row},Matters!$B$8:$B$${MATTERS},0))=TRUE),INDEX(Matters!$D$8:$D$${MATTERS},MATCH($B${row},Matters!$B$8:$B$${MATTERS},0)),"N")`,
          result: formulas(
            displayedMatters,
            paymentSheet,
            row,
            "J",
            attorney,
            persisted,
            persistedMatters,
            persistedData,
            grefs
          ),
        };

        paymentSheet.getCell(`L${row}`).value = {
          formula: `IF(($I${row}="N"),+$H${row}*IF((ISNUMBER(MATCH($B${row},Matters!$B$8:$B$${MATTERS},0))=TRUE),IF((ISNUMBER(INDEX(Matters!$F$8:$F$${MATTERS},MATCH($B${row},Matters!$B$8:$B$${MATTERS},0)))=TRUE),INDEX(Matters!$F$8:$F$${MATTERS},MATCH($B${row},Matters!$B$8:$B$${MATTERS},0)),0.55),0.55),0)`,
          result: formulas(
            displayedMatters,
            paymentSheet,
            row,
            "L",
            attorney,
            persisted,
            persistedMatters,
            persistedData,
            grefs
          ),
        };
        paymentSheet.getCell(`O${row}`).value = {
          formula: `IF(($I${row}="Y"),+$H${row}*IF((ISNUMBER(MATCH($B${row},Matters!$B$8:$B$${MATTERS},0))=TRUE),IF((ISNUMBER(INDEX(Matters!$G$8:$G$${MATTERS},MATCH($B${row},Matters!$B$8:$B$${MATTERS},0)))=TRUE),INDEX(Matters!$G$8:$G$${MATTERS},MATCH($B${row},Matters!$B$8:$B$${MATTERS},0)),0.55), 0.55),0)`,
          result: formulas(
            displayedMatters,
            paymentSheet,
            row,
            "O",
            attorney,
            persisted,
            persistedMatters,
            persistedData,
            grefs
          ),
        };
        paymentSheet.getCell(`P${row}`).value = {
          formula: `IF(($J${row}="Y"),+$E${row}*IF((ISNUMBER(MATCH($B${row},Matters!$B$8:$B$${MATTERS},0))=TRUE),IF((ISNUMBER(INDEX(Matters!$H$8:$H$${MATTERS},MATCH($B${row},Matters!$B$8:$B$${MATTERS},0)))=TRUE),INDEX(Matters!$H$8:$H$${MATTERS},MATCH($B${row},Matters!$B$8:$B$${MATTERS},0)),0.1), 0.1),0)`,
          result: formulas(
            displayedMatters,
            paymentSheet,
            row,
            "P",
            attorney,
            persisted,
            persistedMatters,
            persistedData,
            grefs
          ),
        };
        paymentSheet.getCell(`Q${row}`).value = {
          formula: `IF(($I${row}="Y"),+$E${row}*IF((ISNUMBER(MATCH($B${row},Matters!$B$8:$B$${MATTERS},0))=TRUE),IF((ISNUMBER(INDEX(Matters!$I$8:$I$${MATTERS},MATCH($B${row},Matters!$B$8:$B$${MATTERS},0)))=TRUE),INDEX(Matters!$I$8:$I$${MATTERS},MATCH($B${row},Matters!$B$8:$B$${MATTERS},0)),0.25), 0.25),0)`,
          result: formulas(
            displayedMatters,
            paymentSheet,
            row,
            "Q",
            attorney,
            persisted,
            persistedMatters,
            persistedData,
            grefs
          ),
        };
        paymentSheet.getCell(`R${row}`).value = {
          formula: `IF((ISNUMBER(INDEX(Expenses!$I$7:$I$${EXPENSES},MATCH($A${row},Expenses!$B$7:$B$${EXPENSES},))*$V${row}/INDEX(Expenses!$C$7:$C$${EXPENSES},MATCH($A${row},Expenses!$B$7:$B$${EXPENSES},0)))=TRUE),INDEX(Expenses!$I$7:$I$${EXPENSES},MATCH($A${row},Expenses!$B$7:$B$${EXPENSES},))*$V${row}/INDEX(Expenses!$C$7:$C$${EXPENSES},MATCH($A${row},Expenses!$B$7:$B$${EXPENSES},0)),0)`,
          result: formulas(
            displayedMatters,
            paymentSheet,
            row,
            "R",
            attorney,
            persisted,
            persistedMatters,
            persistedData,
            grefs
          ),
        };
        paymentSheet.getCell(`V${row}`).value = {
          formula: `IF((I${row}="Y"),E${row},0)`,
          result: formulas(
            displayedMatters,
            paymentSheet,
            row,
            "V",
            attorney,
            persisted,
            persistedMatters,
            persistedData,
            grefs
          ),
        };
        paymentSheet.getCell(`W${row}`).value = {
          formula: `IF((ISNUMBER(IF(($M${row}="N"),$L${row},0)+(IF(($F${row}="N"),0,IF(($T${row}="Y"),0,+$Q${row}+$P${row}+$O${row}+$R${row}+IF((ISNUMBER(S${row})=TRUE),S${row},0)))))=TRUE),IF(($M${row}="N"),$L${row},0)+(IF(($F${row}="N"),0,IF(($T${row}="Y"),0,+$Q${row}+$P${row}+$O${row}+$R${row}+IF((ISNUMBER(S${row})=TRUE),S${row},0)))),0)`,
          result: formulas(
            displayedMatters,
            paymentSheet,
            row,
            "W",
            attorney,
            persisted,
            persistedMatters,
            persistedData,
            grefs
          ),
        };
        paymentSheet.getCell(`X${row}`).value = {
          formula: `IF((F${row}="N"),+$Q${row}+$P${row}+$O${row}+$R${row}+IF((ISNUMBER(S${row})=TRUE),S${row},0),0)`,
          result: formulas(
            displayedMatters,
            paymentSheet,
            row,
            "X",
            attorney,
            persisted,
            persistedMatters,
            persistedData,
            grefs
          ),
        };
        paymentSheet.getCell(`Y${row}`).value = {
          formula: `W${row}+X${row}`,
          result: formulas(
            displayedMatters,
            paymentSheet,
            row,
            "Y",
            attorney,
            persisted,
            persistedMatters,
            persistedData,
            grefs
          ),
        };
        totals.total += bill.total;
        grandTotals.total += bill.total;
        totals.paid += revenueAmount;
        grandTotals.paid += revenueAmount;
        totals.guaranteed += calculatedValues["L"][row];
        grandTotals.guaranteed += calculatedValues["L"][row];
        totals.onCollection += calculatedValues["O"][row];
        grandTotals.onCollection += calculatedValues["O"][row];
        totals.responsible += calculatedValues["P"][row];
        grandTotals.responsible += calculatedValues["P"][row];
        totals.originating += calculatedValues["Q"][row];
        grandTotals.originating += calculatedValues["Q"][row];
        totals.celg += calculatedValues["R"][row];
        grandTotals.celg += calculatedValues["R"][row];
        totals.totalOrigination += calculatedValues["V"][row];
        grandTotals.totalOrigination += calculatedValues["V"][row];
        totals.dueNow += calculatedValues["W"][row];
        grandTotals.dueNow += calculatedValues["W"][row];
        totals.dueLater += calculatedValues["X"][row];
        grandTotals.dueLater += calculatedValues["X"][row];
        totals.totalDue += calculatedValues["Y"][row];
        grandTotals.totalDue += calculatedValues["Y"][row];

        sumFormulas[item] = { ...sumFormulas[item], end: row };

        row++;
      });
      //TOTALS
      paymentSheet.getCell(`E${row}`).value = {
        formula: `SUM(E${sumFormulas[item].start}:E${sumFormulas[item].end})`,
        result: totals.total,
      };
      paymentSheet.getCell(`H${row}`).value = {
        formula: `SUM(H${sumFormulas[item].start}:H${sumFormulas[item].end})`,
        result: totals.paid,
      };
      paymentSheet.getCell(`L${row}`).value = {
        formula: `SUM(L${sumFormulas[item].start}:L${sumFormulas[item].end})`,
        result: totals.guaranteed,
      };
      paymentSheet.getCell(`O${row}`).value = {
        formula: `SUM(O${sumFormulas[item].start}:O${sumFormulas[item].end})`,
        result: totals.onCollection,
      };
      paymentSheet.getCell(`P${row}`).value = {
        formula: `SUM(P${sumFormulas[item].start}:P${sumFormulas[item].end})`,
        result: totals.responsible,
      };
      paymentSheet.getCell(`Q${row}`).value = {
        formula: `SUM(Q${sumFormulas[item].start}:Q${sumFormulas[item].end})`,
        result: totals.originating,
      };
      paymentSheet.getCell(`R${row}`).value = {
        formula: `SUM(R${sumFormulas[item].start}:R${sumFormulas[item].end})`,
        result: totals.celg,
      };
      paymentSheet.getCell(`V${row}`).value = {
        formula: `SUM(V${sumFormulas[item].start}:V${sumFormulas[item].end})`,
        result: totals.totalOrigination,
      };
      paymentSheet.getCell(`W${row}`).value = {
        formula: `SUM(W${sumFormulas[item].start}:W${sumFormulas[item].end})`,
        result: totals.dueNow,
      };
      paymentSheet.getCell(`X${row}`).value = {
        formula: `SUM(X${sumFormulas[item].start}:X${sumFormulas[item].end})`,
        result: totals.dueLater,
      };
      paymentSheet.getCell(`Y${row}`).value = {
        formula: `SUM(Y${sumFormulas[item].start}:Y${sumFormulas[item].end})`,
        result: totals.totalDue,
      };

      applyRange(paymentSheet, `A${row}:AA${row}`, "font", {
        name: "Calibri",
        size: 12,
        bold: "true",
      });
      applyRange(paymentSheet, `A${row}:AA${row}`, "fill", {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D9D9D9" },
      });

      paymentSheet.getCell(`E${row}`).numFmt = NUMBER_FORMAT;
      paymentSheet.getCell(`H${row}`).numFmt = NUMBER_FORMAT;
      paymentSheet.getCell(`L${row}`).numFmt = NUMBER_FORMAT;
      paymentSheet.getCell(`O${row}`).numFmt = NUMBER_FORMAT;
      paymentSheet.getCell(`P${row}`).numFmt = NUMBER_FORMAT;
      paymentSheet.getCell(`Q${row}`).numFmt = NUMBER_FORMAT;
      paymentSheet.getCell(`R${row}`).numFmt = NUMBER_FORMAT;
      paymentSheet.getCell(`V${row}`).numFmt = NUMBER_FORMAT;
      paymentSheet.getCell(`W${row}`).numFmt = NUMBER_FORMAT;
      paymentSheet.getCell(`X${row}`).numFmt = NUMBER_FORMAT;
      paymentSheet.getCell(`Y${row}`).numFmt = NUMBER_FORMAT;
      Object.keys(totals).forEach((tot) => (totals[tot] = 0));

      row++;
      row++;
    });
  row += 2;
  //GRAND TOTALS
  paymentSheet.getCell(`A${row}`).value = "Total";
  paymentSheet.getCell(`E${row}`).value = {
    formula:
      "SUM(" +
      Object.values(sumFormulas)
        .map((item) => "E" + (item.end + 1))
        .join(",") +
      ")",
    result: grandTotals.total,
  };
  paymentSheet.getCell(`H${row}`).value = {
    formula:
      "SUM(" +
      Object.values(sumFormulas)
        .map((item) => "H" + (item.end + 1))
        .join(",") +
      ")",
    result: grandTotals.paid,
  };
  paymentSheet.getCell(`L${row}`).value = {
    formula:
      "SUM(" +
      Object.values(sumFormulas)
        .map((item) => "L" + (item.end + 1))
        .join(",") +
      ")",
    result: grandTotals.guaranteed,
  };
  paymentSheet.getCell(`O${row}`).value = {
    formula:
      "SUM(" +
      Object.values(sumFormulas)
        .map((item) => "O" + (item.end + 1))
        .join(",") +
      ")",
    result: grandTotals.onCollection,
  };
  paymentSheet.getCell(`P${row}`).value = {
    formula:
      "SUM(" +
      Object.values(sumFormulas)
        .map((item) => "P" + (item.end + 1))
        .join(",") +
      ")",
    result: grandTotals.responsible,
  };
  paymentSheet.getCell(`Q${row}`).value = {
    formula:
      "SUM(" +
      Object.values(sumFormulas)
        .map((item) => "Q" + (item.end + 1))
        .join(",") +
      ")",
    result: grandTotals.originating,
  };
  paymentSheet.getCell(`R${row}`).value = {
    formula:
      "SUM(" +
      Object.values(sumFormulas)
        .map((item) => "R" + (item.end + 1))
        .join(",") +
      ")",
    result: grandTotals.celg,
  };
  paymentSheet.getCell(`V${row}`).value = {
    formula:
      "SUM(" +
      Object.values(sumFormulas)
        .map((item) => "V" + (item.end + 1))
        .join(",") +
      ")",
    result: grandTotals.totalOrigination,
  };
  paymentSheet.getCell(`W${row}`).value = {
    formula:
      "SUM(" +
      Object.values(sumFormulas)
        .map((item) => "W" + (item.end + 1))
        .join(",") +
      ")",
    result: grandTotals.dueNow,
  };
  paymentSheet.getCell(`X${row}`).value = {
    formula:
      "SUM(" +
      Object.values(sumFormulas)
        .map((item) => "X" + (item.end + 1))
        .join(",") +
      ")",
    result: grandTotals.dueLater,
  };
  paymentSheet.getCell(`Y${row}`).value = {
    formula:
      "SUM(" +
      Object.values(sumFormulas)
        .map((item) => "Y" + (item.end + 1))
        .join(",") +
      ")",
    result: grandTotals.totalDue,
  };

  applyRange(paymentSheet, `A${row}:AA${row}`, "font", {
    name: "Calibri",
    size: 12,
    bold: "true",
  });
  paymentSheet.getCell(`E${row}`).numFmt = NUMBER_FORMAT;
  paymentSheet.getCell(`H${row}`).numFmt = NUMBER_FORMAT;
  paymentSheet.getCell(`L${row}`).numFmt = NUMBER_FORMAT;
  paymentSheet.getCell(`O${row}`).numFmt = NUMBER_FORMAT;
  paymentSheet.getCell(`P${row}`).numFmt = NUMBER_FORMAT;
  paymentSheet.getCell(`Q${row}`).numFmt = NUMBER_FORMAT;
  paymentSheet.getCell(`R${row}`).numFmt = NUMBER_FORMAT;
  paymentSheet.getCell(`V${row}`).numFmt = NUMBER_FORMAT;
  paymentSheet.getCell(`W${row}`).numFmt = NUMBER_FORMAT;
  paymentSheet.getCell(`X${row}`).numFmt = NUMBER_FORMAT;
  paymentSheet.getCell(`Y${row}`).numFmt = NUMBER_FORMAT;
  Object.keys(totals).forEach((tot) => (totals[tot] = 0));

  applyRange(paymentSheet, `A${row}:AA${row}`, "border", {
    top: { style: "thin", color: { argb: "FF000000" } },
    bottom: { style: "double", color: { argb: "FF000000" } },
  });

  applyRange(paymentSheet, `A${row}:AA${row}`, "fill", {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D0CECE" },
  });

  //ALIGNENT
  a5.alignment = {
    vertical: "middle",
    horizontal: "left",
  };
  applyRange(paymentSheet, "B5:Z5", "alignment", {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  });
  applyRange(paymentSheet, "Z7:AA7", "alignment", {
    vertical: "top",
    horizontal: "center",
  });

  //FILL
  applyRange(paymentSheet, "A5:Z5", "fill", {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "DDEBF7" },
  });
  paymentSheet.getCell(`F5`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFFF00" },
  };
  applyRange(paymentSheet, "Z7:AA7", "fill", {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "DDEBF7" },
  });

  //BORDER
  applyRange(paymentSheet, "A5:Y5", "border", {
    top: { style: "thick", color: { argb: "FF000000" } },
    bottom: { style: "thick", color: { argb: "FF000000" } },
  });
  applyRange(paymentSheet, "Z5:AA5", "border", {
    top: { style: "thick", color: { argb: "FF000000" } },
  });
  applyRange(paymentSheet, "Z7:AA7", "border", {
    bottom: { style: "thick", color: { argb: "FF000000" } },
  });
  //FONTS
  applyRange(paymentSheet, "A1:B1", "font", {
    name: "Calibri",
    size: 12,
    bold: "true",
  });
  
};
