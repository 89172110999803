import React, { useContext } from "react";
import Select from "react-dropdown-select";
import { StoreContext } from "../store/Store";
import { actionTypes } from "../store/storeTypes";

export const SelectGroups = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { groups, runGroups } = store;

  const selectStyle = { width: 600, marginTop: 10 };

  return groups ? (
    <div style={selectStyle}>
      <Select
        options={groups.map((item) => {
          return { label: item.id, value: item.id };
        })}
        placeholder="Select groups"
        multi={true}
        values={runGroups.map((item) => {
          return { label: item, value: item };
        })}
        onChange={(values) =>
          dispatch({
            type: actionTypes.SET_RUN_GROUPS,
            payload: values.map((item) => item.value),
          })
        }
      />
    </div>
  ) : null;
};
