import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Invoices } from "./pages/Invoices";
import { Declined } from "./pages/Declined";

// const authServ = new Auth();

const App = () => {
  return (
    <div
      style={{
        fontFamily: "Graphic",
        height: "100vh",
        width: "100vw",
        boxSizing: "border-box",
      }}
    >
      <Routes>
        <Route exact path="/" element={<Invoices />} />
        <Route exact path="/declined" element={<Declined />} />
      </Routes>
    </div>
  );
};
const RoutedApp = () => (
  <Router>
    <App></App>
  </Router>
);

export default RoutedApp;
