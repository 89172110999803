import { useContext } from "react";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";

const endpoints = [
  "bills",
  "matters",
  "persisted matters",
  "persisted payments",
  "persisted expenses",
  "activities"
];

export const EnspointSelectionForDisplay = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { showDataForEndpoint } = store;

  const setEndpoint = (endpoint) => {
    dispatch({
      type: actionTypes.SET_SHOW_DATA_FOR_ENDPOINT,
      payload: endpoint,
    });
  };
  return (
    <div
      style={{
        display: "flex",
        gap: 40,
        paddingTop: 10,
      }}
    >
      {endpoints.map((item, i) => {
        return (
          <label key={i}>
            {item}
            <input
              type="radio"
              checked={showDataForEndpoint === item}
              name="radio"
              onChange={() => setEndpoint(item)}
            />
          </label>
        );
      })}
    </div>
  );
};
