import React, { useContext } from "react";
import { StoreContext } from "../store/Store";
import { Download } from "../reports/Download";

export const Reports = () => {
  const [store] = useContext(StoreContext);
  const { persistedData, clioActivitiesKeyed, clioBills, clioMatters } = store;

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        boxSizing: "border-box",
        margin: 40,
      }}
    >
      <div>
        <h1>Invoices</h1>
        {clioBills &&
        clioMatters &&
        persistedData &&
        Object.keys(persistedData).length > 0 &&
        clioActivitiesKeyed ? (
          <Download />
        ) : !clioBills || !clioMatters || !clioActivitiesKeyed ? (
          <div>First request data from Clio.</div>
        ) : Object.keys(persistedData).length === 0 ? (
          <div>First request persisted Data.</div>
        ) : (
          <div>Something missing.</div>
        )}
      </div>
    </div>
  );
};
