import { useContext, useState } from "react";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";
import { Result } from ".././utils";
import { editGroup, deleteGroup } from "../../api/clio";
import Plus from "../../images/svg/Plus";
import Minus from "../../images/svg/Minus";
import Edit from "../../images/svg/Edit";

const groupStyle = { fontWeight: "bold", margin: 10 };
const memberStyle = { marginLeft: 40 };

export const DisplayGroups = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { auth, groups } = store;
  const [attorneyRemoving, setAttorneyRemoving] = useState("");
  const [groupRemoving, setGroupRemoving] = useState("");

  const editGroupx = (id) => {
    dispatch({ type: actionTypes.SET_GROUP_NAME, payload: id });
    dispatch({
      type: actionTypes.SET_EDITED_GROUP,
      payload: groups.find((group) => group.id === id),
    });
    dispatch({ type: actionTypes.SET_MODE, payload: "edit" });
  };

  const deleteGroupx = (id) => {
    if (window.confirm(`Are you sure you want to delete the ${id} group?`)) {
      setGroupRemoving(id);
      const asyncfunction = async () => {
        await deleteGroup(auth, id);
        const newGroups = groups.filter((item) => {
          return item.id !== id;
        });
        dispatch({ type: actionTypes.SET_GROUPS, payload: newGroups });
        dispatch({
          type: actionTypes.SET_SELECTED_GROUP,
          payload: "",
        });
        setGroupRemoving("");
      };
      asyncfunction();
    }
  };
  const removeAttorney = (group, attorney) => {
    setAttorneyRemoving(attorney);
    const newAttorneys = groups
      .find((item) => {
        return item.id === group;
      })
      .attorneys.filter((att) => {
        return att !== attorney;
      });

    const asyncfunction = async () => {
      const payload = { id: group, attorneys: newAttorneys };
      await editGroup(auth, payload);
      const newGroups = groups
        .filter((item) => {
          return item.id !== group;
        })
        .concat(payload);
      dispatch({ type: actionTypes.SET_GROUPS, payload: newGroups });
      setAttorneyRemoving("");
    };
    asyncfunction();
  };

  return (
    <div>
      {groups
        .sort((a, b) => {
          return a.id.toLowerCase() > b.id.toLowerCase()
            ? 1
            : b.id.toLowerCase() > a.id.toLowerCase()
            ? -1
            : 0;
        })
        .map((group, i) => {
          return (
            <div key={i}>
              <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <div title={`Remove the ${group.id} group.`}>
                  <Minus
                    width={10}
                    height={10}
                    fill="red"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteGroupx(group.id)}
                  />
                </div>
                <div style={groupStyle}>{group.id}</div>
                <div title={`Edit the ${group.id} group.`}>
                  <Edit
                    width={12}
                    height={12}
                    fill="blue"
                    style={{ cursor: "pointer" }}
                    onClick={() => editGroupx(group.id)}
                  />
                </div>
                <div title={`Add attorneys to ${group.id}`}>
                  <Plus
                    width={10}
                    height={10}
                    fill="green"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      dispatch({
                        type: actionTypes.SET_SELECTED_GROUP,
                        payload: group.id,
                      })
                    }
                  />
                </div>
                {groupRemoving === group.id && <Result status="uploading" />}
              </div>
              {group.attorneys.map((attorney, j) => {
                return (
                  <div style={memberStyle} key={j}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <div title={`Remove ${attorney} from ${group.id}`}>
                        <Minus
                          width={10}
                          height={10}
                          fill="red"
                          style={{ cursor: "pointer" }}
                          onClick={() => removeAttorney(group.id, attorney)}
                        />
                      </div>
                      <div
                        style={{
                          marginLeft: 10,
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          gap: 10,
                          padding: 3,
                        }}
                      >
                        {attorney}
                        {attorneyRemoving === attorney && (
                          <Result status="uploading" />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};
