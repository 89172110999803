import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";
import { Result } from "../utils";

export const AttorneyList = () => {
  const [store, dispatch] = useContext(StoreContext);
  const {
    persistedData,
    clioMatters,
    groups,
    selectedGroup,
    selectedGroupAttorney,
    clioActivitiesKeyed,
  } = store;

  const [attorneys, setAttorneys] = useState();
  const [assignedAttorneys, setAssignedAttorneys] = useState();
  const selectStyle = { width: 300, marginTop: 10 };

  const parentStyle = {
    width: 300,
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };
  const buttonStyle = {
    border: "2px solid black",
    borderRadius: 8,
    padding: 6,
    cursor: "pointer",
    marginBottom: 3,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: 10,
  };
  const disabled = {
    border: "1px solid grey",
    borderRadius: 8,
    padding: 6,
    color: "grey",
    marginBottom: 3,
  };

  useEffect(() => {
    let assignedAttorneys = [];
    groups.forEach((group) => {
      assignedAttorneys = assignedAttorneys.concat(group.attorneys);
    });
    setAssignedAttorneys(assignedAttorneys);
  }, [groups]);

  useEffect(() => {
    if (clioMatters && persistedData["expenses"] && clioActivitiesKeyed) {
      const originating = clioMatters.map(
        (item) => item.originating_attorney.name
      );
      const responsible = clioMatters.map(
        (item) => item.responsible_attorney.name
      );

      const actionAttorneysSet = new Set();

      Object.values(clioActivitiesKeyed).forEach((item) => {
        Object.keys(item).forEach((attorney) => {
          actionAttorneysSet.add(attorney);
        });
      });

      const expenses = persistedData["expenses"].map(
        (item) => item["attorney"]
      );
      const newattorneys = []
        .concat(originating)
        .concat(responsible)
        .concat(Array.from(actionAttorneysSet))
        .concat(expenses)
        .filter((item) => item !== "");
      const distinctAttorney = Array.from(new Set(newattorneys));
      setAttorneys(distinctAttorney);
    }
  }, [clioMatters, persistedData, clioActivitiesKeyed]);

  const setAttorney = (val) => {
    dispatch({ type: actionTypes.SET_SELECTED_GROUP_ATTORNEY, payload: val });
  };
  return attorneys && assignedAttorneys && selectedGroup ? (
    <div style={parentStyle}>
      <div style={{ marginTop: 30 }}>Add Attorneys to:</div>
      <div style={{ fontWeight: "bold" }}>{selectedGroup}</div>
      <div style={selectStyle}>
        {attorneys.sort().map((attorney, i) => {
          return (
            <div
              title={
                assignedAttorneys.includes(attorney)
                  ? `${attorney} has already been assigned`
                  : `Add ${attorney} to ${selectedGroup}`
              }
              style={
                assignedAttorneys.includes(attorney) ? disabled : buttonStyle
              }
              key={i}
              onClick={() =>
                assignedAttorneys.includes(attorney)
                  ? undefined
                  : setAttorney(attorney)
              }
            >
              {attorney}
              {attorney === selectedGroupAttorney && (
                <Result status="uploading" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  ) : !attorneys ? (
    <div style={parentStyle}>
      <div style={{ marginTop: 30 }}></div>
      <div>First request data before assigning attorneys to groups</div>
      <div style={selectStyle}></div>
    </div>
  ) : (
    <div style={parentStyle}>
      <div style={{ marginTop: 30 }}></div>
      <div>
        Select a group to add attorneys to by clicking on the + sign next to a
        group name.
      </div>
      <div style={selectStyle}></div>
    </div>
  );
};
