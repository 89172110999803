import { useState, useContext } from "react";
import { Result } from ".././utils";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";
import { editGroup, deleteGroup } from "../../api/clio";

const addGroupStyle = {
  display: "flex",
  gap: 20,
  margin: 40,
};

const buttonStyle = {
  border: "1px solid black",
  borderRadius: 8,
  padding: 6,
  cursor: "pointer",
  display: "inline-block",
  whiteSpace: "nowrap",
};

export const EditGroup = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { auth, editedGroup, groups, groupName, mode } = store;

  const [groupEditing, setGroupEditing] = useState(false);
  const [groupAdding, setGroupAdding] = useState(false);

  const cancelEdit = () => {
    dispatch({ type: actionTypes.SET_GROUP_NAME, payload: "" });
    dispatch({
      type: actionTypes.SET_EDITED_GROUP,
      payload: undefined,
    });
    dispatch({ type: actionTypes.SET_MODE, payload: "add" });
  };

  const saveGroup = () => {
    if (groupName) {
      if (
        !groups.find((item) => {
          return item.id === groupName;
        })
      ) {
        const asyncfunction = async () => {
          setGroupEditing(true);
          await deleteGroup(auth, editedGroup.id);
          const payload = { id: groupName, attorneys: editedGroup.attorneys };
          await editGroup(auth, payload);
          const newGroups = groups
            .filter((item) => {
              return item.id !== editedGroup.id;
            })
            .concat(payload);
          dispatch({ type: actionTypes.SET_GROUPS, payload: newGroups });
          dispatch({ type: actionTypes.SET_GROUP_NAME, payload: "" });
          dispatch({
            type: actionTypes.SET_EDITED_GROUP,
            payload: undefined,
          });
          dispatch({ type: actionTypes.SET_MODE, payload: "add" });
          setGroupEditing(false);
        };
        asyncfunction();
      } else {
        alert("This group name already exists");
      }
    } else {
      alert("The group name is mandatory");
    }
  };

  const newGroup = () => {
    if (groupName) {
      if (
        !groups.find((item) => {
          return item.id === groupName;
        })
      ) {
        const asyncfunction = async () => {
          setGroupAdding(true);
          const payload = { id: groupName, attorneys: [] };
          await editGroup(auth, payload);
          dispatch({ type: actionTypes.SET_GROUP_NAME, payload: "" });
          const newGroups = groups.concat(payload);
          dispatch({ type: actionTypes.SET_GROUPS, payload: newGroups });
          setGroupAdding(false);
        };
        asyncfunction();
      } else {
        alert("This group name already exists");
      }
    } else {
      alert("The group name is mandatory");
    }
  };

  return (
    <div style={addGroupStyle}>
      {mode === "add" ? (
        <div style={{ display: "flex", gap: 10 }}>
          <div
            title={`Add the group ${groupName}.`}
            style={buttonStyle}
            onClick={() => (!groupAdding ? newGroup() : undefined)}
          >
            Add New Group
          </div>
          {groupAdding && <Result status="uploading" />}
        </div>
      ) : (
        <div style={{ display: "flex", gap: 10 }}>
          <div
            title={`Save ${groupName}.`}
            style={buttonStyle}
            onClick={() => saveGroup()}
          >
            Save
          </div>
          {!groupEditing ? (
            <div
              title={`Cancel edit`}
              style={buttonStyle}
              onClick={() => cancelEdit()}
            >
              Cancel
            </div>
          ) : (
            <Result status="uploading" />
          )}
        </div>
      )}
      <input
        value={groupName}
        type="text"
        onChange={(e) =>
          dispatch({
            type: actionTypes.SET_GROUP_NAME,
            payload: e.target.value,
          })
        }
      ></input>
    </div>
  );
};
