export const Declined = ({ mode }) => {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Authorization declined
      </div>
    );
  };
  