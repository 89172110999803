import { saveAs } from "file-saver";
import { payments } from "./payments";
import { matters } from "./matters";
import { expenses } from "./expenses";
import * as Excel from "exceljs";

export const applyRange = (sheet, range, attribute, value) => {
  const from = range.split(":")[0];
  const to = range.split(":")[1];

  const fromsplit = from.match(/(\d+|[^\d]+)/g).join("|");
  const tosplit = to.match(/(\d+|[^\d]+)/g).join("|");

  const fromA = fromsplit.split("|")[0];
  const fromN = fromsplit.split("|")[1];
  const toA = tosplit.split("|")[0];
  const toN = tosplit.split("|")[1];

  const toAN = colToNumber(toA);
  const fromAN = colToNumber(fromA);

  var i;
  var a;

  for (i = fromN; i <= toN; i++) {
    for (a = fromAN; a <= toAN; a++) {
      const c = sheet.getCell(numberToCol(a) + i);
      c[attribute] = value;
    }
  }
};

// Given Column to Number
function colToNumber(str) {
  let num = 0;
  let i = 0;
  while (i < str.length) {
    num = str[i].charCodeAt(0) - 64 + num * 26;
    i++;
  }
  return num;
}

//Given Number to Column name
function numberToCol(num) {
  let str = "",
    q,
    r;
  while (num > 0) {
    q = (num - 1) / 26;
    r = (num - 1) % 26;
    num = Math.floor(q);
    str = String.fromCharCode(65 + r) + str;
  }
  return str;
}

const EXCEL_EXTENSION = ".xlsx";

export const createReport = (
  attorney,
  data,
  mattersData,
  persistedData,
  clioActivitiesKeyed,
  clioActivitiesAttorneyMatters,
  grefs
) => {
  const asyncFunction = async () => {
    const workbook = new Excel.Workbook();
    const paymentSheet = workbook.addWorksheet("Payments");
    payments(
      paymentSheet,
      data,
      attorney,
      mattersData,
      persistedData,
      clioActivitiesKeyed,      
      clioActivitiesAttorneyMatters,
      grefs
    );
    const mattersSheet = workbook.addWorksheet("Matters");
    matters(
      mattersSheet,
      data,
      attorney,
      mattersData,
      persistedData,
      clioActivitiesAttorneyMatters
    );
    const expensesSheet = workbook.addWorksheet("Expenses");
    expenses(expensesSheet, attorney, persistedData, grefs);
    const buffer = await workbook.xlsx.writeBuffer();
    const filetype = "application/vnd.ms-excel;charset=utf-8";
    const blob = new Blob([buffer], { type: filetype });
    saveAs(blob, `${attorney}_${new Date()}${EXCEL_EXTENSION}`);
    console.log("File is written");
  };
  asyncFunction();
};

export const subtractMonths = (date, months) => {
  date.setMonth(date.getMonth() - months);
  return date;
};
