import React, { useContext, useState, useCallback } from "react";
import { StoreContext } from "../store/Store";
import { actionTypes } from "../store/storeTypes";
import { getClioData, getPersistedData } from "../api/clio";
import { DisplayRawData } from "../components/DisplayRawData";
import DatePicker from "react-datepicker";
import { EnspointSelectionForDisplay } from "./data/EnspointSelectionForDisplay";
import { ButtonsToRetrieveData } from "./data/ButtonsToRetrieveData";
import { persisted, clio } from "./datafunctions";

import "react-datepicker/dist/react-datepicker.css";

const formatDate = (date) => {
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

const tokenStyle = { margin: 20 };

export const ClioData = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { auth, access_token, issuedAfter, issuedBefore, status } = store;
  const [errorMessage, setErrorMessage] = useState(null);

  const onClick = useCallback(
    (endpoint) => {
      const issued_after = formatDate(issuedAfter);
      const issued_before = formatDate(issuedBefore);
      if (endpoint === "allData") {
        const asyncfunction = async () => {
          dispatch({
            type: actionTypes.SET_STATUS,
            payload: { allData: "uploading", bills: "uploading" },
          });
          await clio(
            getClioData,
            auth,
            access_token,
            issued_after,
            issued_before,
            dispatch,
            actionTypes,
            status,
            "bills",
            setErrorMessage
          );
          dispatch({
            type: actionTypes.SET_STATUS,
            payload: {
              allData: "uploading",
              bills: "success",
              matters: "uploading",
            },
          });
          await clio(
            getClioData,
            auth,
            access_token,
            issued_after,
            issued_before,
            dispatch,
            actionTypes,
            status,
            "matters",
            setErrorMessage
          );
          dispatch({
            type: actionTypes.SET_STATUS,
            payload: {
              allData: "uploading",
              bills: "success",
              matters: "success",
              persisted: "uploading",
            },
          });
          await persisted(getPersistedData, auth, dispatch, actionTypes);
          dispatch({
            type: actionTypes.SET_STATUS,
            payload: {
              allData: "uploading",
              bills: "success",
              matters: "success",
              persisted: "success",
              activities: "uploading",
            },
          });
          await clio(
            getClioData,
            auth,
            access_token,
            issued_after,
            issued_before,
            dispatch,
            actionTypes,
            status,
            "activities",
            setErrorMessage
          );

          dispatch({
            type: actionTypes.SET_STATUS,
            payload: {
              bills: "success",
              matters: "success",
              persisted: "success",
              activities: "success",
              allData: "success",
            },
          });
        };
        asyncfunction();

        return;
      }
      const asyncfunction = async () => {
        dispatch({
          type: actionTypes.SET_STATUS,
          payload: { ...status, [endpoint]: "uploading" },
        });
        if (endpoint === "persisted") {
          await persisted(getPersistedData, auth, dispatch, actionTypes);
        } else {
          await clio(
            getClioData,
            auth,
            access_token,
            issued_after,
            issued_before,
            dispatch,
            actionTypes,
            status,
            endpoint,
            setErrorMessage
          );
        }
        dispatch({
          type: actionTypes.SET_STATUS,
          payload: { ...status, [endpoint]: "success" },
        });
      };
      asyncfunction();
    },
    [auth, access_token, issuedAfter, issuedBefore, dispatch, status]
  );
  return access_token ? (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        boxSizing: "border-box",
        paddingLeft: 20,
      }}
    >
      <div>
        <h1>Apply parameters and extract Data</h1>

        <div
          style={{
            display: "flex",
            gap: 20,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <div>Issued After: </div>
          <DatePicker
            selected={issuedAfter}
            onChange={(date) =>
              dispatch({ type: actionTypes.SET_ISSUED_AFTER, payload: date })
            }
            dateFormat="yyyy-MM-dd"
          />

          <div>Issued Before: </div>
          <DatePicker
            selected={issuedBefore}
            onChange={(date) =>
              dispatch({ type: actionTypes.SET_ISSUED_BEFORE, payload: date })
            }
            dateFormat="yyyy-MM-dd"
          />
        </div>
        <div style={tokenStyle}>{`Token : ${access_token}`}</div>
        <ButtonsToRetrieveData onClick={onClick} errorMessage={errorMessage} />
        <EnspointSelectionForDisplay />
        <DisplayRawData />
      </div>
    </div>
  ) : null;
};
