import React, { useContext, useEffect } from "react";
import { StoreContext } from "../store/Store";
import { AttorneyList } from "./groups/AttorneyList";
import { EditGroup } from "./groups/EditGroup";
import { DisplayGroups } from "./groups/DisplayGroups";
import { editGroup, getGroups } from "../api/clio";
import { actionTypes } from "../store/storeTypes";

export const Groups = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { auth, groups, selectedGroupAttorney, selectedGroup } = store;

  const parentStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };

  const innerStyle = {};

  useEffect(() => {
    if (selectedGroupAttorney && selectedGroup) {
      const asyncfunction = async () => {
        const payload = groups.find((item) => {
          return item.id === selectedGroup;
        });
        payload.attorneys.push(selectedGroupAttorney);
        await editGroup(auth, payload);
        const newGroups = groups
          .filter((item) => {
            return item.id !== selectedGroup;
          })
          .concat(payload);
        dispatch({ type: actionTypes.SET_GROUPS, payload: newGroups });
        dispatch({
          type: actionTypes.SET_SELECTED_GROUP_ATTORNEY,
          payload: null,
        });
      };
      asyncfunction();
    }
  }, [auth, dispatch, groups, selectedGroupAttorney, selectedGroup]);

  useEffect(() => {
    //get groups
    const asyncfunction = async () => {
      const ret = await getGroups(auth);
      const groups = JSON.parse(ret.body);
      dispatch({ type: actionTypes.SET_GROUPS, payload: groups });
    };
    asyncfunction();
  }, [auth, dispatch]);

  return groups ? (
    <div style={parentStyle}>
      <div style={{ display: "flex" }}>
        <div style={innerStyle}>
          <EditGroup />
          <DisplayGroups />
        </div>
        <AttorneyList />
      </div>
    </div>
  ) : null;
};
