import * as React from "react";
const Minus = (props) => (
  <svg
    fill="#000000"
    id="Capa_1"
    width={800}
    height={800}
    viewBox="0 0 45.402 45.402"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs id="defs1" />
    <g id="g1">
      <path
        d="m 41.267,18.557 c -30.142111,0.0908 -7.828381,0.01 -37.126,0.01 -2.283,0 -4.139,1.851 -4.138,4.135 -0.001,1.141 0.46,2.187 1.207,2.934 0.748,0.749 1.78,1.222 2.92,1.222 l 37.137,-0.001 c 2.283,0 4.134,-1.867 4.133,-4.15 -10e-4,-2.282 -1.85201,-4.156871 -4.133,-4.15 z"
        id="path1"
      />
    </g>
  </svg>
);
export default Minus;
