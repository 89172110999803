import { calculateExpenses } from "./expenses";

const addToCalculatedValues = (cell, row, value, calculatedValues) => {
  if (cell in calculatedValues) {
    calculatedValues[cell][row] = value;
  } else {
    calculatedValues[cell] = { [row]: value };
  }
};

export const formulas = (
  matters,
  sheet,
  row,
  cell,
  attorney,
  persisted,
  persistedMatters,
  persistedData,
  grefs,
  debug = false
) => {
  const calculatedValues = grefs.calculatedPaymentValues.current;
  const calculatedCELGValues =
    attorney in grefs.calculatedCELGValues.current
      ? grefs.calculatedCELGValues.current[attorney]
      : {};

  if (!calculatedCELGValues) {
    calculateExpenses(persistedData, attorney, grefs);
  }

  const fobj = {
    I: () => {
      const b = sheet.getCell(`B${row}`);
      const matter = matters.find((item) => {
        return item.display_number === b.value;
      });
      const calcValue = matter
        ? matter.originating_attorney.name === attorney
          ? "Y"
          : "N"
        : "N";
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
      return calcValue;
    },
    J: () => {
      const b = sheet.getCell(`B${row}`);
      const matter = matters.find((item) => {
        return item.display_number === b.value;
      });
      const calcValue = matter
        ? matter.responsible_attorney.name === attorney
          ? "Y"
          : "N"
        : "N";
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
      return calcValue;
    },
    L: () => {
      const i = getCalculatedValue("I", row);
      let calcValue = 0;
      if (i === "N") {
        const h = sheet.getCell(`H${row}`).value;
        const perc = persistedMatters.Guaranteed
          ? +persistedMatters.Guaranteed
          : 0.55;

        calcValue = +h * perc;
      }
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
      return calcValue;
    },
    O: () => {
      const i = getCalculatedValue("I", row);
      let calcValue = 0;
      if (i === "Y") {
        const h = sheet.getCell(`H${row}`).value;
        const perc = persistedMatters["On-Collection"]
          ? +persistedMatters["On-Collection"]
          : 0.55;

        calcValue = +h * perc;
      }
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
      return calcValue;
    },
    P: () => {
      const i = getCalculatedValue("I", row);
      let calcValue = 0;
      if (i === "Y") {
        const e = sheet.getCell(`E${row}`).value;
        const perc = persistedMatters.Responsible
          ? +persistedMatters.Responsible
          : 0.1;

        calcValue = +e * perc;
      }
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
      return calcValue;
    },
    Q: () => {
      const i = getCalculatedValue("I", row);
      let calcValue = 0;
      if (i === "Y") {
        const e = sheet.getCell(`E${row}`).value;
        const perc = persistedMatters.Originating
          ? +persistedMatters.Originating
          : 0.25;

        calcValue = +e * perc;
      }
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
      return calcValue;
    },
    R: () => {
      const a = sheet.getCell(`A${row}`).value;
      const v = getCalculatedValue("V", row);
      let calcValue = 0;
      if (a in calculatedCELGValues) {
        const expI = calculatedCELGValues[a].i;
        const expC = calculatedCELGValues[a].c;
        if (expC) {
          calcValue = (expI * v) / expC;
        }
      }
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
      return calcValue;
    },
    V: () => {
      const i = getCalculatedValue("I", row);
      let calcValue = 0;
      if (i === "Y") {
        const e = sheet.getCell(`E${row}`).value;
        calcValue = e;
      }
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
      return calcValue;
    },
    W: () => {
      const m = persisted.GuaranteedPrevPaid;
      const l = getCalculatedValue("L", row);
      const f = sheet.getCell(`F${row}`).value;
      const t = persisted.CustomAdjustmentPrevPaid;
      const q = +getCalculatedValue("Q", row);
      const p = +getCalculatedValue("P", row);
      const o = +getCalculatedValue("O", row);
      const r = +getCalculatedValue("R", row);
      const s = +sheet.getCell(`S${row}`).value;

      const L = m === "N" ? l : 0;
      const calcValue = Math.round(
        L + (f === "N" ? 0 : t === "Y" ? 0 : q + p + o + r + s)
      );
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
      return calcValue;
    },
    X: () => {
      const f = sheet.getCell(`F${row}`).value;
      const q = +getCalculatedValue("Q", row);
      const p = +getCalculatedValue("P", row);
      const o = +getCalculatedValue("O", row);
      const r = +getCalculatedValue("R", row);
      const s = +sheet.getCell(`S${row}`).value;

      const calcValue = Math.round(f === "N" ? q + p + o + r + s : 0);
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
      //`IF((F${row}="N"),+$Q${row}+$P${row}+$O${row}+$R${row}+IF((ISNUMBER(S${row})=TRUE),S${row},0),0)`,
      return calcValue;
    },

    Y: () => {
      const x = +getCalculatedValue("X", row);
      const w = +getCalculatedValue("W", row);

      const calcValue = w + x;
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
      return calcValue;
    },
  };

  const getCalculatedValue = (cell, row) => {
    if (cell in calculatedValues && row in calculatedValues[cell]) {
      return calculatedValues[cell][row];
    } else {
      return fobj[cell]();
    }
  };

  return fobj[cell]();
};

export const expenseformulas = (
  row,
  cell,
  persistedExpenses,
  grefs,
  attorney
) => {
  const calculatedValues = grefs.calculatedExpenseValues.current;
  const fobj = {
    E: () => {
      const d = +persistedExpenses.FirmRevenue.replace(",", ".");
      const c = +persistedExpenses.AttorneyOrigination.replace(",", ".");
      const calcValue = d ? c / d : "";
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
    },
    H: () => {
      const g = +persistedExpenses.TotalGeneralExpenses.replace(",", ".");
      const f = +persistedExpenses.AttorneyDirectExpenses.replace(",", ".");
      const e = calculatedValues["E"][row];
      const calcValue = (g + f) * e;
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
    },
    I: () => {
      const b = persistedExpenses.date;
      const c = +persistedExpenses.AttorneyOrigination.replace(",", ".");
      const h = calculatedValues["H"][row];
      const calcValue = h * -1;
      addToCalculatedValues(cell, row, calcValue, calculatedValues);
      if (attorney in grefs.calculatedCELGValues.current) {
        grefs.calculatedCELGValues.current[attorney][b] = {
          i: calcValue,
          c: c,
        };
      } else {
        grefs.calculatedCELGValues.current[attorney] = {
          [b]: { i: calcValue, c: c },
        };
      }
    },
  };

  return fobj[cell]();
};
