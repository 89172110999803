import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../store/Store";
import { actionTypes } from "../../store/storeTypes";
import { addExpense, deleteExpense } from "../../api/clio";

const containerStyle = {
  width: "100%",
  padding: 20,
  marginLeft: -20,
};
const parentStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  gap: 10,
};

const editInputStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: 20,
};

const formatter = new Intl.DateTimeFormat("en", {
  month: "long",
  year: "numeric",
});

const buttonStyle = {
  border: "1px solid black",
  borderRadius: 8,
  padding: 6,
  cursor: "pointer",
};

const EXTRA_NUMBER_WIDTH = 8;

export const EditRow = ({ attorney }) => {
  const [store, dispatch] = useContext(StoreContext);
  const {
    auth,
    expenseRow,
    expensesFiltered,
    persistedData,
    persistedKeyedData,
    tableWidths,
  } = store;
  const [defaultWidth, setDefaultWidth] = useState({
    attorneyDirectExpenses: 208.875,
    attorneyOrigination: 173.33750915527344,
    date: 128.0749969482422,
    firmRevenue: 121.8499984741211,
    totalGeneralExpenses: 193.91250610351562,
  });

  useEffect(() => {
    if (tableWidths) {
      setDefaultWidth(tableWidths);
    }
  }, [tableWidths]);

  const onChange = (e, key) => {
    if (key === "date") {
      const newRow = {
        ...expenseRow,
        date: formatter.format(new Date(e.target.value)),
      };
      dispatch({ type: actionTypes.SET_EXPENSE_ROW, payload: newRow });
    } else {
      const newRow = { ...expenseRow, [key]: +e.target.value };
      dispatch({ type: actionTypes.SET_EXPENSE_ROW, payload: newRow });
    }
  };

  const validateRow = (key, newRow) => {
    if (
      expenseRow &&
      key in expenseRow &&
      (expenseRow[key] || expenseRow[key] === 0)
    ) {
      newRow[key] = expenseRow[key];
      return true;
    } else {
      alert(`${key} is mandatory`);
      return false;
    }
  };
  const onSave = () => {
    const newRow = { attorney: attorney };
    if (!validateRow("date", newRow)) return;
    if (!validateRow("AttorneyDirectExpenses", newRow)) return;
    if (!validateRow("AttorneyOrigination", newRow)) return;
    if (!validateRow("FirmRevenue", newRow)) return;
    if (!validateRow("TotalGeneralExpenses", newRow)) return;
    //Remove existing date
    let newFiltered = expensesFiltered.filter(
      (item) => item.date !== newRow.date
    );
    newFiltered.push(newRow);
    dispatch({ type: actionTypes.SET_EXPENSES_FILTERED, payload: newFiltered });
    //add to persisted data
    const newKeyedExpenses = { ...persistedKeyedData.expenses };
    if (attorney in newKeyedExpenses) {
      newKeyedExpenses[attorney][newRow.date] = newRow;
    } else {
      newKeyedExpenses[attorney] = { [newRow.date]: newRow };
    }
    dispatch({
      type: actionTypes.SET_PERSISTED_KEYED_DATA,
      payload: { ...persistedKeyedData, expenses: newKeyedExpenses },
    });
    const newExpenses = persistedData.expenses.filter(
      (item) => !(item.attorney === attorney && item.date === newRow.date)
    );
    newExpenses.push(newRow);
    dispatch({
      type: actionTypes.SET_PERSISTED_DATA,
      payload: { ...persistedData, expenses: newExpenses },
    });
    // SET_PERSISTED_DATA
    const strNewRow = Object.entries(newRow).reduce((a, b) => {
      return { ...a, [b[0]]: b[1].toString() };
    }, {});
    const asyncfunction = async () => {
      await addExpense(auth, strNewRow);
    };
    asyncfunction();
  };

  const onClear = () => {
    dispatch({ type: actionTypes.SET_EXPENSE_ROW, payload: null });
  };
  const onDelete = () => {
    if (expenseRow && "date" in expenseRow && expenseRow.date) {
      if (window.confirm(`Delete ${expenseRow.date}`)) {
        let newFiltered = expensesFiltered.filter(
          (item) => item.date !== expenseRow.date
        );
        dispatch({
          type: actionTypes.SET_EXPENSES_FILTERED,
          payload: newFiltered,
        });
        //
        const newKeyedExpenses = { ...persistedKeyedData.expenses };
        delete newKeyedExpenses[attorney][expenseRow.date];
        dispatch({
          type: actionTypes.SET_PERSISTED_KEYED_DATA,
          payload: { ...persistedKeyedData, expenses: newKeyedExpenses },
        });
        const newExpenses = persistedData.expenses.filter(
          (item) =>
            !(item.attorney === attorney && item.date === expenseRow.date)
        );
        dispatch({
          type: actionTypes.SET_PERSISTED_DATA,
          payload: { ...persistedData, expenses: newExpenses },
        });

        dispatch({ type: actionTypes.SET_EXPENSE_ROW, payload: null });
        const asyncfunction = async () => {
          await deleteExpense(auth, attorney, expenseRow.date);
        };
        asyncfunction();
      }
    } else {
      alert(`enter a date to delete`);
    }
  };

  return defaultWidth && attorney ? (
    <div style={containerStyle}>
      <div style={parentStyle}>
        <div style={buttonStyle} onClick={() => onClear()}>
          Clear
        </div>
        <div style={buttonStyle} onClick={() => onSave()}>
          Save
        </div>
        <div style={buttonStyle} onClick={() => onDelete()}>
          Delete
        </div>
      </div>
      <div style={editInputStyle}>
        <input
          style={{ width: `${defaultWidth.date}px` }}
          value={
            expenseRow
              ? new Date(`${expenseRow.date} 01`).getFullYear() +
                "-" +
                (
                  "00" +
                  (new Date(`${expenseRow.date} 01`).getMonth() + 1)
                ).slice(-2)
              : ""
          }
          type="month"
          onChange={(e) => onChange(e, "date")}
        ></input>
        <input
          style={{
            width: `${
              defaultWidth.attorneyDirectExpenses - EXTRA_NUMBER_WIDTH
            }px`,
          }}
          value={
            expenseRow ? +(+expenseRow.AttorneyDirectExpenses).toFixed(2) : ""
          }
          type="number"
          onChange={(e) => onChange(e, "AttorneyDirectExpenses")}
        ></input>
        <input
          style={{
            width: `${defaultWidth.attorneyOrigination - EXTRA_NUMBER_WIDTH}px`,
          }}
          value={
            expenseRow ? +(+expenseRow.AttorneyOrigination).toFixed(2) : ""
          }
          type="number"
          onChange={(e) => onChange(e, "AttorneyOrigination")}
        ></input>
        <input
          style={{
            width: `${defaultWidth.firmRevenue - EXTRA_NUMBER_WIDTH}px`,
          }}
          value={expenseRow ? +(+expenseRow.FirmRevenue).toFixed(2) : ""}
          type="number"
          onChange={(e) => onChange(e, "FirmRevenue")}
        ></input>
        <input
          style={{
            width: `${
              defaultWidth.totalGeneralExpenses - EXTRA_NUMBER_WIDTH
            }px`,
          }}
          value={
            expenseRow ? +(+expenseRow.TotalGeneralExpenses).toFixed(2) : ""
          }
          type="number"
          onChange={(e) => onChange(e, "TotalGeneralExpenses")}
        ></input>
      </div>
    </div>
  ) : null;
};
