import { useEffect, useState, useContext } from "react";
import { flatten } from "./utils";
import { StoreContext } from "../store/Store";

const containerStyle = {
  width: "100%",
  overflow: "auto",
  marginTop: 20,
  fontSize: 10,
};
const tableStyle = { borderCollapse: "collapse" };
const tdStyle = {
  borderRight: "1px solid grey",
  borderLeft: "1px solid grey",
  padding: 3,
  whiteSpace: "nowrap",
};

export const DisplayRawData = () => {
  const [store] = useContext(StoreContext);
  const [headers, setHeaders] = useState(null);
  const [flatData, setFlatData] = useState(null);
  const {
    clioBills,
    clioMatters,
    clioActivities,
    showDataForEndpoint,
    persistedData,
  } = store;

  useEffect(() => {
    if (showDataForEndpoint.includes("persisted")) {
      if (
        showDataForEndpoint === "persisted matters" &&
        "matters" in persistedData &&
        persistedData.matters.length > 0
      ) {
        const order = {
          id: 1,
          Guaranteed: 2,
          "On-Collection": 3,
          Responsible: 4,
          Originating: 5,
        };
        setFlatData(persistedData.matters);
        const firstItem = persistedData.matters[0];
        setHeaders(
          Object.keys(firstItem).sort((a, b) => {
            return order[a] > order[b] ? 1 : order[b] > order[a] ? -1 : 0;
          })
        );
      }
      if (
        showDataForEndpoint === "persisted payments" &&
        "payments" in persistedData &&
        persistedData.payments.length > 0
      ) {
        const order = {
          id: 1,
          invoice: 2,
          InvoicedAmountAtty: 2.5,
          GuaranteedPrevPaid: 3,
          CustomAdjustment: 4,
          CustomAdjustmentPrevPaid: 5,
          GuaranteedPaymentDate: 6,
          OnCollectionPaymentDate: 7,
        };
        setFlatData(
          persistedData.payments.sort((a, b) => {
            return +a.invoice > +b.invoice
              ? 1
              : +b.invoice > +a.invoice
              ? -1
              : 0;
          })
        );
        const firstItem = persistedData.payments[0];

        setHeaders(
          Object.keys(firstItem).sort((a, b) => {
            return order[a] > order[b] ? 1 : order[b] > order[a] ? -1 : 0;
          })
        );
      }
      if (
        showDataForEndpoint === "persisted expenses" &&
        "expenses" in persistedData &&
        persistedData.expenses.length > 0
      ) {
        const order = {
          attorney: 1,
          date: 2,
          AttorneyOrigination: 3,
          FirmRevenue: 4,
          AttorneyDirectExpenses: 5,
          TotalGeneralExpenses: 6,
        };
        setFlatData(
          persistedData.expenses.sort((a, b) => {
            const ad = new Date(a.date);
            const bd = new Date(b.date);
            return ad > bd ? 1 : bd > ad ? -1 : 0;
          })
        );
        const firstItem = persistedData.expenses[0];
        setHeaders(
          Object.keys(firstItem).sort((a, b) => {
            return order[a] > order[b] ? 1 : order[b] > order[a] ? -1 : 0;
          })
        );
      }
    }
    if (showDataForEndpoint === "activities" && clioActivities) {
      const flat = flatten(clioActivities);
      // const flat = flatten(
      //   clioActivities.filter((f) => f.bill && f.bill.number === "156418")
      //   // clioActivities.filter((f) => f.bill && f.bill.number === "156426")
      // );
      let flato = {};
      Object.entries(flat).forEach((item) => {
        const replaced = item[0].replace(".", "||");
        const split = replaced.split("||");
        const index = split[0];
        const key = split[1].split(".").join("_");

        if (index in flato) {
          flato[index][key] = item[1];
        } else {
          flato[index] = { [key]: item[1] };
        }
      });

      const flatdata = Object.values(flato);
      setFlatData(flatdata);

      //flatten data
      const firstItem = flatdata[0];
      setHeaders(Object.keys(firstItem));
    }
    if (showDataForEndpoint === "bills" && clioBills) {
      const flat = flatten(clioBills);
      let flato = {};
      Object.entries(flat).forEach((item) => {
        const replaced = item[0].replace(".", "||");
        const split = replaced.split("||");
        const index = split[0];
        const key = split[1].split(".").join("_");

        if (index in flato) {
          flato[index][key] = item[1];
        } else {
          flato[index] = { [key]: item[1] };
        }
      });

      const flatdata = Object.values(flato);
      setFlatData(flatdata);

      //flatten data
      const firstItem = flatdata[0];
      setHeaders(Object.keys(firstItem));
    }
    if (showDataForEndpoint === "matters" && clioMatters) {
      const flat = flatten(clioMatters);
      let flato = {};
      Object.entries(flat).forEach((item) => {
        const replaced = item[0].replace(".", "||");
        const split = replaced.split("||");
        const index = split[0];
        const key = split[1].split(".").join("_");

        if (index in flato) {
          flato[index][key] = item[1];
        } else {
          flato[index] = { [key]: item[1] };
        }
      });

      const flatdata = Object.values(flato);
      setFlatData(flatdata);

      //flatten data
      const firstItem = flatdata[0];
      setHeaders(Object.keys(firstItem));
    }
  }, [
    clioBills,
    clioMatters,
    showDataForEndpoint,
    persistedData,
    clioActivities,
  ]);

  return headers && flatData ? (
    <div style={containerStyle}>
      <table style={tableStyle}>
        <thead>
          <tr>
            {headers.map((item, i) => {
              return <th key={`h${i}`}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {flatData.map((item, j) => {
            return (
              <tr key={`r${j}`}>
                {headers.map((row, k) => {
                  return (
                    <td key={`d${k}`} style={tdStyle}>
                      {item[row]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : null;
};
