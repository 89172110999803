export const fieldsparameters = {
  activities: `type,
      date,
      quantity_in_hours,
      quantity,
      price,
      total,
      bill{number},
      user{name},
      matter{display_number}`,
  bills: `number,
      issued_at,
      created_at,
      due_at,
      tax_rate,
      secondary_tax_rate,
      updated_at,
      type,
      start_at,
      end_at,
      balance,
      state,
      kind,
      total,
      paid,
      paid_at,
      pending,
      due,
      credits_issued,
      services_secondary_tax,
      services_sub_total,
      services_tax,
      services_taxable_sub_total,
      services_secondary_taxable_sub_total,
      taxable_sub_total,
      secondary_taxable_sub_total,
      sub_total,
      tax_sum,
      secondary_tax_sum,
      total_tax,
      available_state_transitions,
      client {
        name,
        first_name,
        middle_name,
        last_name,
        date_of_birth,
        type,
        created_at,
        updated_at,
        prefix,
        title,
        initials,
        clio_connect_email,
        locked_clio_connect_email,
        client_connect_user_id,
        primary_email_address,
        secondary_email_address,
        primary_phone_number,
        secondary_phone_number,
        ledes_client_id,
        has_clio_for_clients_permission,
        is_client,
        is_clio_for_client_user,
        is_co_counsel,
        is_bill_recipient,
        sales_tax_number,
      },
      matters
        {
          id,
          number,
          display_number,
          custom_number,
          description,
          status,
          location,
          client_reference,
          client_id,
          billable,
          maildrop_address,
          billing_method,
          open_date,
          close_date,
          pending_date,
          created_at,
          updated_at,
          shared,
          has_tasks,
          last_activity_date,
          matter_stage_updated_at,
        },
      user
        {
          id
          ,name
        }
      `,
      matters: `id,
        display_number,
        client
          {
            name
          },
        responsible_attorney
          {
            id,
            name,
            last_name
          },
        originating_attorney
          {
            id,
            name,
            last_name
          }`
};
