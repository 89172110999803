import { useContext, useState, useEffect } from "react";
import { StoreContext } from "../store/Store";
import { actionTypes } from "../store/storeTypes";
import { validateEmail } from "./utils";

const containerStyle = {
  height: "100%",
  width: "100%",
  display: "flex",
  // flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  // gap: 10,
};

const innerStyle = { display: "table", border: "2px solid black", padding: 30 };
const rowStyle = { display: "table-row", height: 30 };
const cellStyle = { display: "table-cell" };
const forgotStyle = {
  fontSize: 12,
  color: "blue",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

export const GetCredentials = () => {
  const [store, dipatch] = useContext(StoreContext);
  const { auth, loggedIn } = store;

  const [rememberMe, setRememberMe] = useState(false);
  const [localCred, setLocalCred] = useState({});
  const [message, setMessage] = useState("");

  useEffect(() => {
    const ls = localStorage.getItem("climate-edge-credentials");
    if (ls) {
      setLocalCred(
        JSON.parse(localStorage.getItem("climate-edge-credentials"))
      );
      setRememberMe(true);
    }
  }, []);

  const onClickForgot = () => {
    console.log("localCred", localCred);
    if (validateEmail(localCred.user)) {
      const asyncfunction = async () => {
        const ret = await auth.resetPassword(localCred.user);
        if (ret.success) {
          dipatch({
            type: actionTypes.SET_FORGOT_PASSWORD,
            payload: { status: true, user: localCred.user },
          });
        } else {
          setMessage(ret.errorMessage);
        }
      };
      asyncfunction();
      // auth.resetPassword()
      // dipatch({ type: actionTypes.SET_FORGOT_PASSWORD, payload: true });
      setMessage("");
    } else {
      setMessage("Invalid Email");
    }
  };

  const onClick = () => {
    if (rememberMe) {
      localStorage.setItem(
        "climate-edge-credentials",
        JSON.stringify(localCred)
      );
    } else {
      localStorage.removeItem("climate-edge-credentials");
    }
    dipatch({
      type: actionTypes.SET_CREDENTIALS,
      payload: localCred,
    });
  };

  return (
    <div style={containerStyle}>
      <div>
        {!loggedIn.status && (
          <div style={{ color: "red" }}>{loggedIn.message}</div>
        )}
        {message && <div style={{ color: "red" }}>{message}</div>}
        <div style={innerStyle}>
          <div style={rowStyle}>
            <div style={cellStyle}>Email</div>
            <input
              style={{ ...cellStyle, width: 260 }}
              type="text"
              id="email"
              value={localCred && localCred.user ? localCred.user : ""}
              onChange={(e) =>
                setLocalCred({ ...localCred, user: e.target.value })
              }
            />
          </div>

          <div style={rowStyle}>
            <div style={cellStyle}>Password</div>
            <input
              style={{ ...cellStyle, width: 260 }}
              type="password"
              id="password"
              value={localCred && localCred.password ? localCred.password : ""}
              onChange={(e) =>
                setLocalCred({ ...localCred, password: e.target.value })
              }
            />
          </div>
          <div style={{ ...rowStyle, height: undefined }}>
            <div style={{ ...cellStyle, paddingRight: 20 }}>Remember me</div>
            <div style={cellStyle}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <input
                  type="checkbox"
                  id="rememberme"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <div style={forgotStyle} onClick={() => onClickForgot()}>
                  Forgot password
                </div>
                <button style={{ cursor: "pointer" }} onClick={() => onClick()}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
