import { useContext, useState } from "react";
import { StoreContext } from "../store/Store";
import { actionTypes } from "../store/storeTypes";

const containerStyle = {
  height: "100%",
  width: "100%",
  display: "flex",
  // flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  // gap: 10,
};

const innerStyle = { display: "table", padding: 30 };
const rowStyle = { display: "table-row", height: 30 };
const cellStyle = { display: "table-cell", marginLeft: 10 };
const messageStyle = { color: "red" };

export const ForgotPassword = () => {
  const [store, dipatch] = useContext(StoreContext);
  const { auth, forgotPassword } = store;

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");

  const onClick = () => {
    if (password1 === password2) {
      const asyncfunction = async () => {
        const ret = await auth.completeReset(
          forgotPassword.user,
          code,
          password1
        );
        if (ret.success) {
          dipatch({
            type: actionTypes.SET_FORGOT_PASSWORD,
            payload: { status: false, user: "" },
          });
          dipatch({
            type: actionTypes.SET_LOGGED_IN,
            payload: { status: false, message: "" },
          });
        } else {
          setMessage(ret.errorMessage);
        }
      };
      asyncfunction();
    } else {
      setMessage("The passwords do not match.");
    }
  };

  return (
    <div style={containerStyle}>
      <div style={{ border: "2px solid black", padding: 20 }}>
        <div>{`enter the code that was sent to ${forgotPassword.user}`}</div>
        <div style={innerStyle}>
          <div style={rowStyle}>
            <div style={cellStyle}>Code</div>
            <input
              style={cellStyle}
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>

          <div style={rowStyle}>
            <div style={cellStyle}>Password</div>
            <input
              style={cellStyle}
              type="password"
              id="password1"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
            />
          </div>

          <div style={rowStyle}>
            <div style={cellStyle}>Confirm Password</div>
            <input
              style={cellStyle}
              type="password"
              id="password2"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
            />
          </div>
          <div style={{ ...rowStyle, height: undefined }}>
            <div style={{ ...cellStyle, paddingRight: 20 }}></div>
            <div style={cellStyle}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  onClick={() => onClick()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={messageStyle}>{message}</div>
      </div>
    </div>
  );
};
