import React, { useContext } from "react";
import { StoreContext } from "../store/Store";
import { LoadExpenses } from "./expenses/LoadExpenses";

export const Expenses = () => {
  const [store] = useContext(StoreContext);
  const { persistedData, clioMatters } = store;

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      {clioMatters && persistedData && Object.keys(persistedData).length > 0 ? (
        <LoadExpenses />
      ) : !clioMatters ? (
        <div>First request matters from Clio.</div>
      ) : !persistedData || Object.keys(persistedData).length === 0 ? (
        <div>First request persisted Data.</div>
      ) : (
        <div>First request revenue Report.</div>
      )}{" "}
    </div>
  );
};
