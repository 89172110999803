export const actionTypes = {
  SET_AUTH: "SET_AUTH",
  SET_CLIO_ACTIVITIES: "SET_CLIO_ACTIVITIES",
  SET_CLIO_ACTIVITIES_KEYED: "SET_CLIO_ACTIVITIES_KEYED",
  SET_CLIO_ACTIVITIES_ATTORNEY_MATTER: "SET_CLIO_ACTIVITIES_ATTORNEY_MATTER",
  SET_CLIO_BILLS: "SET_CLIO_BILLS",
  SET_CLIO_MATTERS: "SET_CLIO_MATTERS",
  SET_CREDENTIALS: "SET_CREDENTIALS",
  SET_EDITED_GROUP: "SET_EDITED_GROUP",
  SET_EXPENSE_ROW: "SET_EXPENSE_ROW",
  SET_EXPENSES_FILTERED: "SET_EXPENSES_FILTERED",
  SET_FORGOT_PASSWORD: "SET_FORGOT_PASSWORD",
  SET_ISSUED_AFTER: "SET_ISSUED_AFTER",
  SET_ISSUED_BEFORE: "SET_ISSUED_BEFORE",
  SET_LOGGED_IN: "SET_LOGGED_IN",
  SET_MODE: "SET_MODE",
  SET_GROUPS: "SET_GROUPS",
  SET_GROUP_NAME: "SET_GROUP_NAME",
  SET_PERSISTED_DATA: "SET_PERSISTED_DATA",
  SET_PERSISTED_KEYED_DATA: "SET_PERSISTED_KEYED_DATA",
  SET_CODE: "SET_CODE",
  SET_RUN_OPTION: "SET_RUN_OPTION",
  SET_RUN_ATTORNEYS: "SET_RUN_ATTORNEYS",
  SET_RUN_GROUPS: "SET_RUN_GROUPS",
  SET_SELECTED: "SET_SELECTED",
  SET_SELECTED_GROUP: "SET_SELECTED_GROUP",
  SET_SELECTED_GROUP_ATTORNEY: "SET_SELECTED_GROUP_ATTORNEY",
  SET_STATUS: "SET_STATUS",
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SET_SHOW_DATA_FOR_ENDPOINT: "SET_SHOW_DATA_FOR_ENDPOINT",
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
  SET_REQUIRE_NEW_PASSWORD: "SET_REQUIRE_NEW_PASSWORD",
  SET_TABLE_WIDTHS: "SET_TABLE_WIDTHS",
};
