import React, { useRef, createContext } from "react";
export const GlobalRefContext = createContext(undefined);

export const GlobalRefProvider = ({ children }) => {
  const calculatedPaymentValues = useRef({});
  const calculatedExpenseValues = useRef({});
  const calculatedCELGValues = useRef({});
  const status = useRef({});

  const refs = {
    calculatedPaymentValues,
    calculatedExpenseValues,
    calculatedCELGValues,
    status,
  };

  return refs ? (
    <GlobalRefContext.Provider value={refs}>
      {children}
    </GlobalRefContext.Provider>
  ) : null;
};
