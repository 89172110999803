import React, { useContext, useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { StoreContext } from "../../store/Store";
import { ExpenseTable } from "./ExpenseTable";
import { EditRow } from "./EditRow";
import { actionTypes } from "../../store/storeTypes";

export const LoadExpenses = () => {
  const [store, dispatch] = useContext(StoreContext);
  const { clioMatters, persistedData } = store;
  const [attorneys, setAttorneys] = useState();
  const [attorney, setAttorney] = useState();

  const selectStyle = { width: 300, marginTop: 10 };

  const parentStyle = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };

  useEffect(() => {
    if (clioMatters && persistedData["expenses"]) {
      const originating = clioMatters.map(
        (item) => item.originating_attorney.name
      );
      const responsible = clioMatters.map(
        (item) => item.responsible_attorney.name
      );

      const expenses = persistedData["expenses"].map(
        (item) => item["attorney"]
      );
      const newattorneys = []
        .concat(originating)
        .concat(responsible)
        .concat(expenses)
        .filter((item) => item !== "");
      const distinctAttorney = Array.from(new Set(newattorneys));

      const attorneyList = distinctAttorney.map((atty, i) => {
        return {
          label: atty,
          value: atty,
        };
      });
      setAttorneys(attorneyList);
    }
  }, [clioMatters, persistedData]);

  useEffect(() => {
    dispatch({
      type: actionTypes.SET_EXPENSES_FILTERED,
      payload: persistedData["expenses"].filter(
        (item) => item.attorney === attorney
      ),
    });
  }, [attorney, persistedData, dispatch]);

  return attorneys ? (
    <div style={parentStyle}>
      <div style={selectStyle}>
        <Select
          options={attorneys}
          onChange={(values) => setAttorney(values[0].value)}
        />
      </div>
      <div style={{ width: "100%" }}>
        <EditRow attorney={attorney} />
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <ExpenseTable attorney={attorney} />
      </div>
    </div>
  ) : null;
};
